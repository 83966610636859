
import React, { useState,useEffect } from 'react';
import { Box, Modal, Button, TablePagination, Grid } from '@material-ui/core';
import UploadButton from './UploadButton';
import ImageListGallery from './ImageListGallery';
import instance from '../../helpers';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';

const style = {
    position: 'absolute',
    top: '50%',
    left: 'calc(50% + 120px)',
    transform: 'translate(-50%, -50%)',
    width: "75%",
    // height: "75%",
    bgcolor: '#1a1a1a',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const fetchDelete = async (id,path='gallery')=>{
    const response = await instance(true).delete(`/${path}/${id}`);
    return response.data;
}
const fetch = async (limit,page,path='gallery')=>{
    const response = await instance(true).get(`/${path}?page=${page}&limit=${limit}&sortBy=createdAt,desc`);
    return response.data;
}

const ModalGallery = ({ field,handleClose,open,pageSize,setPageSize,pageIndex,setPageIndex,galleryProduct,setGalleryProduct,path,cbPick}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { data, status } = useQuery([pageIndex,pageSize,open], () => fetch(pageSize,pageIndex,path), { enabled: open,cacheTime:0 });

    const initialValues={[field.name]:data && data.data ?  data.data : [],data}
    const [gallery, setGallery] = useState(initialValues);

    // set gallery new values    
  useEffect(() => {
    setGallery(initialValues)
  }, [data]);
  

    const removeImageInGallery = async (id,name) =>{
        const dataFetchDelete = await fetchDelete(id,path);
        if(dataFetchDelete._id === id){
            const data = gallery[name].filter(e => e._id !== id);
            initialValues[name] = data;
            setGallery({[name]:data});
        }else{
            enqueueSnackbar(`Error remove Image In Gallery`, { variant: 'error' , autoHideDuration: 1000});
        }
    }

    const pickImageInGallery = async (id,name) =>{
            const find = initialValues[name].find(e=> e._id === id);
            if(find){
                if(Array.isArray(galleryProduct[name])){
                    galleryProduct[name].push(find)
                    setGalleryProduct({[name]:galleryProduct[name]});
                }else{
                    galleryProduct[name] = find
                    setGalleryProduct({[name]:galleryProduct[name]});
                    if(cbPick){
                        cbPick(find)
                    }
                }
            }else{
                enqueueSnackbar(`Error picking Image In Gallery`, { variant: 'error' , autoHideDuration: 1000});
            }
           
    }

    return (
        
            <Modal
            className='admin'
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <div className='scroll' style={{maxHeight:'50vh'/*,overflowY:'scroll',overflowX:'hidden'*/}}>
                     <ImageListGallery  field={field} galleryProduct={galleryProduct} pickImageInGallery={pickImageInGallery} actionFn={removeImageInGallery} gallery={gallery} setGallery={setGallery}/>
                    </div>
                    <Grid container spacing={2}>
  <Grid item xs={8}>
    <UploadButton name={field.name} gallery={gallery} setGallery={setGallery}  firstArr={true} multiple={true} path={path}/>
  </Grid>
  <Grid item xs={4}>
  <TablePagination
        component="div"
        count={initialValues && initialValues.data && initialValues.data.totalCount || 0}
        rowsPerPage={initialValues && initialValues.data && initialValues.data.limit || 10}
        page={pageIndex}
        onPageChange={(event, newPage) => setPageIndex(newPage)}
        onRowsPerPageChange={(event) => {
          setPageSize(parseInt(event.target.value, 10));
          setPageIndex(0);
        }}
      />
  </Grid>
  
</Grid>
                    {/* <UploadButton name={field.name} gallery={gallery} setGallery={setGallery}/>

                    <TablePagination
        component="div"
        count={initialValues.data.totalCount}
        rowsPerPage={initialValues.data.limit}
        page={pageIndex}
        onPageChange={(event, newPage) => setPageIndex(newPage)}
        onRowsPerPageChange={(event) => {
          setPageSize(parseInt(event.target.value, 10));
          setPageIndex(0);
        }}
      /> */}
                </Box>
            </Modal>
        
    );
}

export default ModalGallery;