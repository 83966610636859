// import React, { useState } from 'react';
// import { Stepper, Step, StepLabel, Button, Typography, Container, Select, MenuItem, FormControl, InputLabel, TextField, Grid, Box } from '@mui/material';
// import { Formik, Form, Field } from 'formik';
// import { TextField as FormikTextField, Select as FormikSelect } from 'formik-mui';
// import { MobileDatePicker } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { LocalizationProvider } from '@mui/lab';
// import * as Yup from 'yup';

// function getSteps() {
//   return ['Select a Picture', 'Enter Profile Details', 'Billing Information'];
// }

// function Step1Form({ formik }) {
//   // Assume images are statically imported or sourced
//   const images = [
//     { id: 1, src: '/path/to/image1.jpg', alt: 'Image 1' },
//     { id: 2, src: '/path/to/image2.jpg', alt: 'Image 2' },
//     { id: 3, src: '/path/to/image3.jpg', alt: 'Image 3' }
//   ];

//   return (
//     <Grid container spacing={2}>
//       {images.map((image) => (
//         <Grid item xs={12} sm={4} key={image.id}>
//           <img src={image.src} alt={image.alt} style={{ width: '100%', height: 'auto' }} />
//           <Field
//             component={FormikSelect}
//             name={`selectedImage${image.id}`}
//             label="Select Option"
//             fullWidth
//           >
//             <MenuItem value="Option1">Option 1</MenuItem>
//             <MenuItem value="Option2">Option 2</MenuItem>
//             <MenuItem value="Option3">Option 3</MenuItem>
//           </Field>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function Step2Form() {
//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="fullName" label="Meno a priezvisko zosnulého" fullWidth />
//       </Grid>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="biography" label="Biografia" multiline rows={4} fullWidth />
//       </Grid>
//       <Grid item xs={6}>
//         <Field
//           component={MobileDatePicker}
//           name="birthDate"
//           label="Dátum narodenia"
//           inputFormat="dd/MM/yyyy"
//           renderInput={(params) => <TextField {...params} />}
//         />
//       </Grid>
//       <Grid item xs={6}>
//         <Field
//           component={MobileDatePicker}
//           name="deathDate"
//           label="Dátum úmrtia"
//           inputFormat="dd/MM/yyyy"
//           renderInput={(params) => <TextField {...params} />}
//         />
//       </Grid>
//     </Grid>
//   );
// }

// function Step3Form() {
//   return (
//     <Grid container spacing={2}>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="email" label="E-mail" fullWidth />
//       </Grid>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="phone" label="Telefón" fullWidth />
//       </Grid>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="name" label="Meno a priezvisko" fullWidth />
//       </Grid>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="street" label="Ulica a číslo domu" fullWidth />
//       </Grid>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="city" label="Mesto" fullWidth />
//       </Grid>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="postalCode" label="PSČ" fullWidth />
//       </Grid>
//       <Grid item xs={12}>
//         <Field component={FormikTextField} name="country" label="Štát" fullWidth />
//       </Grid>
//     </Grid>
//   );
// }

// function getStepContent(stepIndex, formikProps) {
//     console.log('getStepContent',stepIndex)
//   switch (stepIndex) {
//     case 0:
//       return <Step1Form formik={formikProps} />;
//     case 1:
//       return <Step2Form />;
//     case 2:
//       return <Step3Form />;
//     default:
//       return 'Unknown step';
//   }
// }

// export default function CheckoutStepper() {
//   const [activeStep, setActiveStep] = useState(0);
//   const steps = getSteps();

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   return (
//     <Container>
//       <Stepper activeStep={activeStep}>
//         {steps.map((label, index) => (
//           <Step key={label}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//       <Formik
//         initialValues={{
//           selectedImage1: '',
//           selectedImage2: '',
//           selectedImage3: '',
//           fullName: '',
//           biography: '',
//           birthDate: new Date(),
//           deathDate: new Date(),
//           email: '',
//           phone: '',
//           name: '',
//           street: '',
//           city: '',
//           postalCode: '',
//           country: ''
//         }}
//         validationSchema={Yup.object({
//           email: Yup.string().email('Invalid email address').required('Required'),
//           phone: Yup.string().required('Required'),
//           name: Yup.string().required('Required'),
//           street: Yup.string().required('Required'),
//           city: Yup.string().required('Required'),
//           postalCode: Yup.string().required('Required'),
//           country: Yup.string().required('Required')
//         })}
//         onSubmit={(values, { setSubmitting }) => {
//           setTimeout(() => {
//             alert(JSON.stringify(values, null, 2));
//             setSubmitting(false);
//           }, 400);
//         }}
//       >
//         {formik => (
//           <Form>
//             <LocalizationProvider dateAdapter={AdapterDateFns}>
//               <div>
//                 {getStepContent(activeStep, formik)}
//                 dsadassa
//                 <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
//                   <Button
//                     color="inherit"
//                     disabled={activeStep === 0}
//                     onClick={handleBack}
//                     sx={{ mr: 1 }}
//                   >
//                     Back
//                   </Button>
//                   <Box sx={{ flex: '1 1 auto' }} />
//                   <Button onClick={handleNext}>
//                     {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
//                   </Button>
//                 </Box>
//               </div>
//             </LocalizationProvider>
//           </Form>
//         )}
//       </Formik>
//       {activeStep === steps.length && (
//         <React.Fragment>
//           <Typography sx={{ mt: 2, mb: 1 }}>
//             All steps completed - you&apos;re finished
//           </Typography>
//           <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
//             Reset
//           </Button>
//         </React.Fragment>
//       )}
//     </Container>
//   );
// }




// import React, { useState } from 'react';
// import { Stepper, Step, StepLabel, Button, Typography, Container } from '@mui/material';
// import { Formik, Form, Field } from 'formik';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import SelectImageForm from './SelectImageForm';

// const steps = ['Select Image', 'Deceased Info', 'Billing Info'];

// function getStepContent(stepIndex, setFieldValue, values) {
//   switch (stepIndex) {
//     case 0:
//       return <SelectImageForm setFieldValue={setFieldValue} values={values}/>;
//     case 1:
//       return <SelectImageForm setFieldValue={setFieldValue} values={values}/>;
//     case 2:
//       return <SelectImageForm setFieldValue={setFieldValue} values={values}/>;
//     default:
//       return 'Unknown step';
//   }
// }

// const StepperComponent = () => {
//   const [activeStep, setActiveStep] = useState(0);

//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleSubmit = (values, actions) => {
//     if (activeStep === steps.length - 1) {
//       // Submit the final form data
//       console.log(values);
//     } else {
//       handleNext();
//       actions.setTouched({});
//       actions.setSubmitting(false);
//     }
//   };

//   return (
//     <Container>
//       <Stepper activeStep={activeStep}>
//         {steps.map((label) => (
//           <Step key={label}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//       <Formik
//         initialValues={{
//           selectedImage: '',
//           deceasedName: '',
//           biography: '',
//           birthDate: null,
//           deathDate: null,
//           billingInfo: '',
//           email: '',
//           phone: '',
//           fullName: '',
//           address: '',
//           city: '',
//           postalCode: '',
//           country: ''
//         }}
//         onSubmit={handleSubmit}
//       >
//         {({ setFieldValue, values }) => (
//           <Form>
//             {getStepContent(activeStep, setFieldValue, values)}
//             <div>
//               <Button disabled={activeStep === 0} onClick={handleBack}>
//                 Back
//               </Button>
//               <Button variant="contained" color="primary" type="submit">
//                 {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
//               </Button>
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </Container>
//   );
// };

// export default StepperComponent;


import React, { useState } from 'react';
import { Stepper, Step, StepLabel, Typography, Container, Box } from '@mui/material';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import { useQuery } from 'react-query';
import instance from '../../helpers';

const fetchDataCategories = async () => {
  const response = await instance().get(`/plaques`);
  return response.data;
};

function App() {
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState([]);
  const dataFetch = useQuery([], () => fetchDataCategories(), { cacheTime: 0 });

  const images = dataFetch.data?.data.map((e, index) => ({ ...e, value: index })) || [];
  
  const steps = ['Vyberte si dizajn', 'Informácie o zosnulom', 'Fakturačné údaje', 'Sumár'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <StepOne handleNext={handleNext} setData={setData} images={images} data={data} />;
      case 1:
        return <StepTwo handleNext={handleNext} handleBack={handleBack} setData={setData} data={data} />;
      case 2:
        return <StepThree handleNext={handleNext} handleBack={handleBack} setData={setData} data={data} />;
      case 3:
        return <StepFour handleNext={handleNext} handleBack={handleBack} setData={setData} data={data} images={images} />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Container sx={{ background: '#FEF7F0', mt: 1, pb: 4 }}>
      <Container maxWidth="md">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>
                <Typography variant="caption" sx={{ }}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
      <Box sx={{ mt: 4 }}>
        {getStepContent(activeStep)}
      </Box>
    </Container>
  );
}

export default App;
