import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';  // Assuming you have a Home component
// import About from './components/About';  // Assuming you have an About component
import NotFound from './pages/NotFound';  // Assuming you have a NotFound component
import About from './pages/About';  // Assuming you have a NotFound component
import Info from './pages/Info';  // Assuming you have a NotFound component
import Order from './pages/Order';  // Assuming you have a NotFound component
import Profile from './pages/Profile';
import ThankYou from './pages/thankyou';
import Profiles from './pages/Profiles';
import ProtectedRoute from './ProtectedRoute';
import TablePage from './pages/TablePage';
import EditPage from './pages/EditPage';
import CreatePage from './pages/CreatePage';
import Auth from './pages/AuthPage';

import Cancel from './pages/Cancel';
import Success from './pages/Success';
import PartnersPage from './pages/Partners';
import MembersPage from './pages/Members';

import Status from './pages/settings/Status';
import StatusProfile from './pages/settings/StatusProfile';

import Client from './pages/settings/Client';
import OrderS from './pages/settings/Order';
import Product from './pages/settings/Product';
import Profil from './pages/settings/Profil';
import Plaque from './pages/settings/Plaque';
import QR from './pages/settings/QR';
import Discount from './pages/settings/Discount';
import Payments from './pages/settings/Payments';
import Partners from './pages/settings/Partners';



import Admin from './layouts/AdminLayout';
function App() {
  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
        <Route path="/" element={<Home />} />
        <Route path="/order/:id" element={<Order />} />
        <Route path="about" element={<About />} />
        <Route path="info" element={<Info />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profiles/:id" element={<Profiles />} />

        <Route path="/thank-you" element={<ThankYou />} />

        <Route path="/admin" element={<ProtectedRoute><Auth isAdmin={true}/></ProtectedRoute>} />


    
        <Route path="/status" element={<ProtectedRoute><TablePage name={'status'} options={Status}/></ProtectedRoute>} />
        <Route path="/status/create" element={<ProtectedRoute>< CreatePage name={'status'} options={Status}/></ProtectedRoute>} />
        <Route path="/status/edit/:id" element={<ProtectedRoute>< EditPage name={'status'} options={Status}/></ProtectedRoute>} />
      
        <Route path="/status-profile" element={<ProtectedRoute><TablePage name={'status-profile'} options={StatusProfile}/></ProtectedRoute>} />
        <Route path="/status-profile/create" element={<ProtectedRoute>< CreatePage name={'status-profile'} options={StatusProfile}/></ProtectedRoute>} />
        <Route path="/status-profile/edit/:id" element={<ProtectedRoute>< EditPage name={'status-profile'} options={StatusProfile}/></ProtectedRoute>} />
      

        <Route path="/clients" element={<ProtectedRoute><TablePage name={'clients'} options={Client}/></ProtectedRoute>} />
        <Route path="/clients/create" element={<ProtectedRoute>< CreatePage name={'clients'} options={Client}/></ProtectedRoute>} />
        <Route path="/clients/edit/:id" element={<ProtectedRoute>< EditPage name={'clients'} options={Client}/></ProtectedRoute>} />
        
        <Route path="/orders" element={<ProtectedRoute><TablePage name={'orders'} options={OrderS}/></ProtectedRoute>} />
        <Route path="/orders/create" element={<ProtectedRoute>< CreatePage name={'orders'} options={OrderS}/></ProtectedRoute>} />
        <Route path="/orders/edit/:id" element={<ProtectedRoute>< EditPage name={'orders'} options={OrderS}/></ProtectedRoute>} />

        <Route path="/qr" element={<ProtectedRoute><TablePage name={'qr'} options={QR}/></ProtectedRoute>} />
        <Route path="/qr/create" element={<ProtectedRoute>< CreatePage name={'qr'} options={QR}/></ProtectedRoute>} />
        <Route path="/qr/edit/:id" element={<ProtectedRoute>< EditPage name={'qr'} options={QR}/></ProtectedRoute>} />


        <Route path="/products" element={<ProtectedRoute><TablePage name={'products'} options={Product}/></ProtectedRoute>} />
        <Route path="/products/create" element={<ProtectedRoute>< CreatePage name={'products'} options={Product}/></ProtectedRoute>} />
        <Route path="/products/edit/:id" element={<ProtectedRoute>< EditPage name={'products'} options={Product}/></ProtectedRoute>} />


        <Route path="/profiles" element={<ProtectedRoute><TablePage name={'profiles'} options={Profil}/></ProtectedRoute>} />
        <Route path="/profiles/create" element={<ProtectedRoute>< CreatePage name={'profiles'} options={Profil}/></ProtectedRoute>} />
        <Route path="/profiles/edit/:id" element={<ProtectedRoute>< EditPage name={'profiles'} options={Profil}/></ProtectedRoute>} />


        <Route path="/plaques" element={<ProtectedRoute><TablePage name={'plaques'} options={Plaque}/></ProtectedRoute>} />
        <Route path="/plaques/create" element={<ProtectedRoute>< CreatePage name={'plaques'} options={Plaque}/></ProtectedRoute>} />
        <Route path="/plaques/edit/:id" element={<ProtectedRoute>< EditPage name={'plaques'} options={Plaque}/></ProtectedRoute>} />

        <Route path="/discounts" element={<ProtectedRoute><TablePage name={'discounts'} options={Discount}/></ProtectedRoute>} />
        <Route path="/discounts/create" element={<ProtectedRoute>< CreatePage name={'discounts'} options={Discount}/></ProtectedRoute>} />
        <Route path="/discounts/edit/:id" element={<ProtectedRoute>< EditPage name={'discounts'} options={Discount}/></ProtectedRoute>} />


        <Route path="/payments" element={<ProtectedRoute><TablePage name={'payments'} options={Payments}/></ProtectedRoute>} />
        <Route path="/payments/create" element={<ProtectedRoute>< CreatePage name={'payments'} options={Payments}/></ProtectedRoute>} />
        <Route path="/payments/edit/:id" element={<ProtectedRoute>< EditPage name={'payments'} options={Payments}/></ProtectedRoute>} />
        

        <Route path="/partners" element={<ProtectedRoute><TablePage name={'partners'} options={Partners}/></ProtectedRoute>} />
        <Route path="/partners/create" element={<ProtectedRoute>< CreatePage name={'partners'} options={Partners}/></ProtectedRoute>} />
        <Route path="/partners/edit/:id" element={<ProtectedRoute>< EditPage name={'partners'} options={Partners}/></ProtectedRoute>} />
        
        <Route path="/partner" element={<PartnersPage />} />


        <Route path="/members" element={<MembersPage />} />


        <Route path="/cancel" element={<Cancel />} />
        <Route path="/success" element={<Success />} />
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
