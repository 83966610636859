import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationSK from './locales/sk/translation.json';
import translationCS from './locales/cs/translation.json';

// Nastavenie zdrojov
const resources = {
  sk: { translation: translationSK },
  cs: { translation: translationCS },
};

i18n
  .use(initReactI18next) // init i18next do react-i18next
  .init({
    resources,
    lng: 'sk', // Predvolený jazyk
    fallbackLng: 'sk', // Záložný jazyk, ak nie je preklad dostupný
    interpolation: {
      escapeValue: false, // React už štandardne escapuje
    },
  });

export default i18n;
