import axios from 'axios';

class Token {
  constructor(){
    this.token = localStorage.getItem('token');
  }

 
  get(){
    if(!this.token){
      this.token = localStorage.getItem('token');
    }
    return this.token;
  }

  set(token){
    this.token = token;
    return token;
  }
}

class Middleware {
  constructor(options){
    this.token = new Token();
    this.options = options;
    this.init();
  }

 async init(){
    this.instance =await this.createInstance();
  }

  async createInstance(){
    return await axios.create(this.options);
  }

  get(isAdmin = false){
    if(isAdmin && this.token.token !== localStorage.getItem('token-admin')){
      this.reInstance(localStorage.getItem('token-admin'))
    }
    return this.instance;
  }

  async reInstance(token){
    this.options.headers['Authorization'] = 'Bearer '+token;
    await this.init();
  }
}

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


const isDev = process.env.NODE_ENV;
export const serverURL = isDev === 'development' ? 'http://localhost' : 'https://navzdy.online'//'https://34.159.73.40'//'http://34.107.96.70'
const middleware = new Middleware({
  baseURL: `${serverURL}/api`,
  // timeout: 1000,
  // withCredentials: true,
  headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Authorization": 'Bearer '+localStorage.getItem('token'),
      // "Cookie": 'key='+getCookie('boo-key')+';'
    }
});

// const token = localStorage.getItem('token');
// const baseURL = 'https://project-transport-api-op27xobcrq-lm.a.run.app/api'//'http://localhost:3000/api';
// const instance = axios.create({
//     baseURL,
//     timeout: 1000,
//     headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//         'Authorization': 'Bearer '+token
//     }
//   });
  

export function uploadFile(blobOrFile,cbResponse,path,cbProgress) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', `${serverURL}/api/${path}`, true);
  xhr.onload = () => {
    if(cbResponse){
      cbResponse(xhr.response);
    } 
  };

  xhr.upload.onprogress = () => { 
    if(cbProgress){
      cbProgress()
    }
   };

  xhr.send(blobOrFile);
}

export const reInstance = middleware.reInstance.bind(middleware);

export default middleware.get.bind(middleware);