import React, { useState,useEffect } from 'react';
import { useQuery } from 'react-query';
import instance, { serverURL } from '../helpers';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel, TablePagination, Menu, MenuItem, IconButton, ImageList, ImageListItem, Chip } from '@material-ui/core';
import LoadingIndicator from './LoadingIndicator';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';

// formating date format MM/DD/YYYY
function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

const fetchTableData = async (name, pageIndex, pageSize, sortBy, sortByCol) => {
  const response = await instance(true).get(`/${name}?page=${pageIndex}&limit=${pageSize}&sortBy=${sortBy ? `${sortBy},` : ''}${sortByCol}`);
  return response.data;
};

const GenericTable = ({ columns,name }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortBy, setSortBy] = useState('');
  const [sortByCol, setSortByCol] = useState('');
  const [isDelete, setIsDelete] = useState(false);


  const { data, status } = useQuery([name, pageIndex, pageSize, sortBy, sortByCol, isDelete], () => fetchTableData(name, pageIndex, pageSize, sortBy, sortByCol));
  


  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    // Reset state when the URL changes
    setPageIndex(0);
  }, [location]);
  

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const handleEdit = () => {
    navigate(`/${name}/edit/${currentRow._id}`); 
    handleMenuClose();
  };

  
  const handleEditRow = (id) => {
    navigate(`/${name}/edit/${id}`); 
  };

  const handleDelete = async () => {
    await instance(true).delete(`/${name}/${currentRow._id}`);
    handleMenuClose();
    setIsDelete(!isDelete)
  };


  const handleCreate = () => {
    navigate(`/${name}/create`);
  };


  
  if (status === 'loading') return LoadingIndicator;
  if (status === 'error') {
    enqueueSnackbar(`Error fetching data /${name}`, { variant: 'error', autoHideDuration: 1000 });
  };

  return (
    <Paper>
        <div style={{ display: 'flex',  justifyContent: 'space-between',alignItems: 'center', padding: '10px' }}>
            <Typography variant="h6" style={{ padding: '10px' }}>{name.toUpperCase()}</Typography>
            <Button variant="contained" color="primary" onClick={handleCreate}>
              Vytvoriť nový
            </Button>
        </div>
      <TableContainer style={{ padding: '10px' }}>
        <Table>
          <TableHead>
            {columns.map(column => (
              <TableCell key={column.accessor}>
                {column.Header}
                <TableSortLabel
                  active={sortBy === column.accessor}
                  direction={sortBy === column.accessor ? sortByCol : 'desc'}
                  onClick={() => {
                    if(column.accessor !== sortBy){    
                        setSortBy(column.accessor);
                        setSortByCol('desc')
                    }else{
                        setSortByCol(sortByCol === 'asc' ? 'desc' : 'asc')
                    }
                
                }}
                />
              </TableCell>
            ))}
            <TableCell key={'action'} style={{textAlign:'right'}}>
                Akcie
                
              </TableCell>
          </TableHead>
          <TableBody>
            {data.data.map(row => (
              <TableRow key={row._id}>
                {columns.map(column => (
                  <TableCell key={column.accessor} onClick={ row.deletedAt ? () => {} : (e) => handleEditRow(row._id)} >
                    {column.type === 'date' ? formatDate(new Date(row[column.accessor])) :
                      column.type === 'qr' ? <img width={68} loading="lazy" src={row[column.accessor]} /> :
                    column.multiline ? `${row[column.accessor]?.length >= 40 ?  `${row[column.accessor]?.substring(0,40)} ...` :  row[column.accessor]||'' }`:
                    column.type === 'form' ? `` :
                    
                    (column.type === 'autocomplete' || column.type === 'scan' ) && row[column.accessor] && row[column.accessor][column.populate] && !column.multiple ? <Chip onClick={!column.link ? null : (e)=>{e.stopPropagation();navigate(`${column.link}/edit/${row[column.accessor]._id}`)}}  label={row[column.accessor] && row[column.accessor][column.populate] ? row[column.accessor][column.populate] : ''} variant="Chip Filled" /> : column.type === 'autocomplete' && column.multiple ? <Stack direction="row" spacing={1}>{row[column.accessor].slice(0, 4).map(e=> <Chip onClick={!column.link ? null : (ee)=>{ee.stopPropagation();navigate(`${column.link}/edit/${e?._id || ''}`)}} label={e[column.populate]} variant="Chip Filled" />)}</Stack> : 
                    column.type === 'gallery' ? row[column.accessor] ?  
                    
                   ( row[column.accessor] && row[column.accessor][0] && row[column.accessor][0][column.populate] && <ImageList sx={{ width: 500, height: 450 }} cols={1} rowHeight={64}>
                     
                        <ImageListItem >
                    <img width={68} loading="lazy" src={`${serverURL}/uploaded-files/${row[column.accessor] && row[column.accessor][0] && row[column.accessor][0][column.populate]}`} />
                    </ImageListItem>
                    </ImageList> )
                    : ''  : 
                     column.type === 'image' ? row[column.accessor][column.populate].split('.')[1] === 'svg' ? <div className="list-icons" dangerouslySetInnerHTML={{ __html:  row[column.accessor].data || '' }}></div> : <img height={100} src={`${serverURL}/uploaded-files/${row[column.accessor] && row[column.accessor][column.populate] && row[column.accessor][column.populate]}`} /> : 
                     typeof row[column.accessor] === "boolean" ? String(row[column.accessor]) : row[column.accessor]}
                  </TableCell>
                ))}
{/* {gallery[field.name] && gallery[field.name][field.populate].split('.')[1] === 'svg' ? <div className='upload-icon-form' dangerouslySetInnerHTML={{ __html: gallery[field.name].data || '' }}></div>} */}
                {row.deletedAt ? '' : <TableCell style={{textAlign:'right'}}>
                    <IconButton onClick={(e) => handleMenuClick(e, row)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleEdit}>Upraviť</MenuItem>
                        <MenuItem onClick={handleDelete}>Zmazať</MenuItem>
                    </Menu>
                </TableCell>}

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={data.totalCount}
        rowsPerPage={pageSize}
        page={pageIndex}
        onPageChange={(event, newPage) => setPageIndex(newPage)}
        onRowsPerPageChange={(event) => {
          setPageSize(parseInt(event.target.value, 10));
          setPageIndex(0);
        }}
      />
    </Paper>
  );
};

export default GenericTable;
