import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { uploadFile } from '../../helpers';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  

const UploadButton = ({ name,gallery,setGallery,firstArr,cb,multiple,path,left}) => {
  
    const handleFileUpload = async (e,initialValues,name) => {
        if (!e.target.files) {
          return;
        }
        const file = e.target.files[0];
        if(e.target.files.length === 1){
        uploadFile(file,(response)=>{
          if(response){
            let json ;
            try {
              json = JSON.parse(response);

            } catch (error) {
              console.log('Error JSON parse',error)
            }
            if(json && initialValues && Array.isArray(initialValues)){
              // TODO ZIP
              if(Array.isArray(json)){
                json.forEach(element => firstArr ? initialValues.unshift(element) : initialValues.push(element));
              // One file
              }else{
                
                  if(firstArr){
                      initialValues.unshift(json)
                  }else{
                      initialValues.push(json)
                  }
               
              }
              
             
              setGallery({[name]:initialValues})
              if(cb){
                cb(json)
              }
              // not array,single image
            } else{
              setGallery({[name]:json})
              if(cb){
                cb(json)
              }
            }
    
          }
        },path)
        // multiple files
        // TODO refactor
      }else if(e && e.target && e.target.files){
        for (let index = 0; index < e.target.files.length; index++) {
          const file = e.target.files[index];       
        uploadFile(file,(response)=>{
          if(response){
            let json ;
            try {
              json = JSON.parse(response);

            } catch (error) {
              console.log('Error JSON parse',error)
            }
            if(json && initialValues && Array.isArray(initialValues)){
              // TODO ZIP
              if(Array.isArray(json)){
                json.forEach(element => firstArr ? initialValues.unshift(element) : initialValues.push(element));
              // One file
              }else{
                if(firstArr){
                    initialValues.unshift(json)
                }else{
                    initialValues.push(json)
                }
              }
             
              setGallery({[name]:initialValues})
              if(cb){
                cb(json)
              }
            }
    
          }
        },path)
      };
      }
      }
    return ( <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUpload />}
        style={{color:'white',marginTop:10,float: left ? 'left' : 'none'}}
      >
        Upload File
        <VisuallyHiddenInput type="file" onChange={(e) => handleFileUpload(e,gallery[name],name)} multiple={multiple}/>
      </Button>);
}

export default UploadButton;
