import React from 'react';
import { Container, Typography, Box } from '@mui/material';
// import Layout from './Layout';
import { useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import instance from '../helpers';
import LoadingIndicator from '../components/LoadingIndicator';
import { useTranslation } from 'react-i18next';

const fetchData = async (sessionId,isSessionId) => {
  const response = await instance().get(`/success-payment?${isSessionId ? 'session_id' : 'id'}=${sessionId}`);
  return response.data;
};

function Success() {
  const { t } = useTranslation();

  const location = useLocation();

  const getSessionId = () => {
    const params = new URLSearchParams(location.search);
    return params.get('session_id');
  };

  
  const getId = () => {
    const params = new URLSearchParams(location.search);
    return params.get('id');
  };

  let sessionId = getSessionId();
  const id = getId();
  let isSessionId = true

  if(sessionId === null && id){
    isSessionId = false;
    sessionId = id;
  }

  const { enqueueSnackbar } = useSnackbar();
  const { data, status } = useQuery([sessionId], () => fetchData(sessionId,isSessionId),{cacheTime:0});
  if (status === 'loading') return LoadingIndicator;
  if (status === 'error') {
      enqueueSnackbar(`Error`, { variant: 'error', autoHideDuration: 1000 });
  };

  return (
    // <Layout>
    <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
        {data ? t('sucess1') : t('sucess2') }
        </Typography>
        <Typography variant="body1">
        {data ? t('sucess3') : t('sucess4') }
        </Typography>
      </Box>
    </Container>
    // </Layout>
  );
}

export default Success;
