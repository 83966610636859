import React, { useContext } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Zadajte platný e-mail')
    .required('Vyžaduje sa e-mail'),
  password: Yup.string()
    .required('Vyžaduje sa heslo')
    .min(8, 'Heslo by malo mať minimálne 8 znakov'),
});

function LoginModal({ open, handleClose }) {
  const { t } = useTranslation();

  const { login, register } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    const {status,role} = await login(values);
    if(status){
      if(role === 'partner'){
        navigate('/members')
      }else{
        navigate('/profile')
      }
     
    }
  }
  return (
    <Dialog open={open} onClose={handleClose} color='#fef7f0' >
      <DialogTitle style={{textTransform:'uppercase'}}>{t('logined')}</DialogTitle>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // console.log(values);
          actions.setSubmitting(false);
          handleClose(); // Close modal on successful login
          handleLogin(values)
        }}
      >
        {({ errors, touched }) => (
          <Form style={{color:'#fef7f0'}}>
            <DialogContent>
              <Field
                as={TextField}
                name="email"
                label="Email"
                fullWidth
                margin="normal"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                className="emailLogin"
              />
              <Field
                as={TextField}
                type="password"
                name="password"
                label="Heslo"
                fullWidth
                margin="normal"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                className="passwordLogin"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined" color="secondary">
              {t('canceled')}
              </Button>
              <Button type="submit"  variant="outlined" color="primary">
              {t('login')} 
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default LoginModal;
