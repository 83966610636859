import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Grid from '@mui/material/Grid';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@mui/material/IconButton';

import { serverURL } from '../../helpers';

export default function StandardImageList({ itemData, edit, setGallery }) {
  const action = (item) => {
    setGallery({ gallery: itemData.filter(e => e._id !== item._id) });
  };

  return (
    <ImageList sx={{ width: '100%', height: 'auto' }} cols={4} gap={8}>
      {itemData?.map((item) => (
        <ImageListItem key={item._id}>
          <img
            srcSet={`${serverURL}/uploaded-files/${item.src}`}
            src={`${serverURL}/uploaded-files/${item.src}`}
            alt={item.title}
            loading="lazy"
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
          {edit && (
            <ImageListItemBar
              position="top"
              actionIcon={
                <IconButton
                  aria-label={`delete ${item.title}`}
                  onClick={() => action(item)}
                  sx={{ color: 'white' }}
                >
                  <Delete />
                </IconButton>
              }
              sx={{
                background: 'rgba(0, 0, 0, 0.5)',
              }}
            />
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
}
