// import React from 'react';
// import { Formik, Form, Field } from 'formik';
// import { TextField, Button, Box } from '@mui/material';
// import { Typography } from '@mui/material';

// function StepThree({ handleBack, handleNext, setData, data }) {
//   return (
//     <Formik
//       initialValues={data[2] ? data[2] : {  email: '', phone: '', fullName: '', streetAddress: '', city: '', postalCode: '', country: '' }}
//       onSubmit={(values) => {
//         setData([data[0],data[1],values])
//         handleNext();
//       }}
//     >
//       {({ values, handleChange }) => (
//         <Form>
//           <Typography variant="h6">Fakturačné údaje</Typography>
//           <Field as={TextField} name="email" label="E-mail" type="email" onChange={handleChange} value={values.email} fullWidth margin="normal" />
//           <Field as={TextField} name="phone" label="Telefón" onChange={handleChange} value={values.phone} fullWidth margin="normal" />
//           <Field as={TextField} name="fullName" label="Meno a priezvisko" onChange={handleChange} value={values.fullName} fullWidth margin="normal" />
//           <Field as={TextField} name="streetAddress" label="Ulica a číslo domu" onChange={handleChange} value={values.streetAddress} fullWidth margin="normal" />
//           <Field as={TextField} name="city" label="Mesto" onChange={handleChange} value={values.city} fullWidth margin="normal" />
//           <Field as={TextField} name="postalCode" label="PSČ" type="number" onChange={handleChange} value={values.postalCode} fullWidth margin="normal" />
//           <Field as={TextField} name="country" label="Štát" onChange={handleChange} value={values.country} fullWidth margin="normal" />
//           <Button onClick={handleBack}>Späť</Button>
//           <Button type="submit">Pokračovať</Button>
//         </Form>
//       )}
//     </Formik>
//   );
// }

// export default StepThree;
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, Typography, Box } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

// Validation schema defined previously
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Neplatný e-mailový formát')
    .required('E-mail je povinný'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Telefónne číslo musí obsahovať iba čísla')
    .min(9, 'Telefónne číslo musí mať aspoň 9 číslic')
    .required('Telefón je povinný'),
  fullName: Yup.string()
    .required('Meno a priezvisko je povinné')
    .min(3, 'Meno a priezvisko by mali mať aspoň 3 znaky'),
  streetAddress: Yup.string()
    .required('Ulica a číslo domu sú povinné')
    .min(5, 'Ulica a číslo domu by mali mať aspoň 5 znakov'),
  city: Yup.string()
    .required('Mesto je povinné')
    .min(2, 'Mesto by malo mať aspoň 2 znaky'),
  postalCode: Yup.number()
    .required('PSČ je povinné')
    .positive('Neplatné PSČ')
    .integer('PSČ musí byť celé číslo'),
  country: Yup.string()
    .required('Štát je povinný')
    .min(2, 'Názov štátu by mal mať aspoň 2 znaky'),
});

function StepThree({ handleBack, handleNext, setData, data }) {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={data[2] ? data[2] : { email: '', phone: '', fullName: '', streetAddress: '', city: '', postalCode: '', country: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setData([data[0],data[1],values]);
        handleNext();
      }}
    >
      {({ values, handleChange, errors, touched }) => (
        <Form>
          <Typography variant="h6">{t('stepper3')}</Typography>
          <Field as={TextField} name="email" label="E-mail" type="email" onChange={handleChange} value={values.email} error={touched.email && !!errors.email} helperText={touched.email && errors.email} fullWidth margin="normal" />
          <Field as={TextField} name="phone" label="Telefón" onChange={handleChange} value={values.phone} error={touched.phone && !!errors.phone} helperText={touched.phone && errors.phone} fullWidth margin="normal" />
          <Field as={TextField} name="fullName" label="Meno a priezvisko" onChange={handleChange} value={values.fullName} error={touched.fullName && !!errors.fullName} helperText={touched.fullName && errors.fullName} fullWidth margin="normal" />
          <Field as={TextField} name="streetAddress" label="Ulica a číslo domu" onChange={handleChange} value={values.streetAddress} error={touched.streetAddress && !!errors.streetAddress} helperText={touched.streetAddress && errors.streetAddress} fullWidth margin="normal" />
          <Field as={TextField} name="city" label="Mesto" onChange={handleChange} value={values.city} error={touched.city && !!errors.city} helperText={touched.city && errors.city} fullWidth margin="normal" />
          <Field as={TextField} name="postalCode" label="PSČ" type="number" onChange={handleChange} value={values.postalCode} error={touched.postalCode && !!errors.postalCode} helperText={touched.postalCode && errors.postalCode} fullWidth margin="normal" />
          <Field as={TextField} name="country" label="Štát" onChange={handleChange} value={values.country} error={touched.country && !!errors.country} helperText={touched.country && errors.country} fullWidth margin="normal" />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>

          <Button onClick={handleBack} variant="outlined" color='secondary'>{t('back')}</Button>
          <Button type="submit" variant="outlined" >{t('continue')}</Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default StepThree;
