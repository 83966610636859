import React from 'react';
import { useParams } from 'react-router-dom';
import GenericForm from '../components/GenericForm';
import AdminLayout from '../layouts/AdminLayout';
import instance from '../helpers';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '../components/LoadingIndicator';
import { useSnackbar } from 'notistack';

  
const fetchData = async (data) => {
  const name = data.queryKey[0]
  const id = data.queryKey[1]

  const response = await instance(true).get(`/${name}/${id}`);
  return response.data;
  };
  
const EditPage = ({name, options}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, status } = useQuery([name,id],fetchData,{ initialData: undefined ,cacheTime:0});

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await instance(true).put(`/${name}/${id}`, values);
     if(response && response.status === 200){
        navigate(`/${name}`);
     }
     // resetForm();
    } catch (error) {
      enqueueSnackbar(`Error submitting: ${error}`, { variant: 'error', autoHideDuration: 1000 });
    }
  };

  
  if (status === 'loading') return LoadingIndicator;
  if (status === 'error'){
    enqueueSnackbar(`Error fetching data /${name}`, { variant: 'error' , autoHideDuration: 1000});
  }

  return (
    <AdminLayout>
        <div style={{ margin: '20px' }}>
           <GenericForm initialValues={data} onSubmit={handleSubmit} fields={options()} title={`EDIT ${name.toUpperCase()}`} />
        </div>
    </AdminLayout>
  );
};

export default EditPage;
