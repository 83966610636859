const options = (isTable = false) =>{
    const data = 
    [
      { name: 'title', label: 'Titulok', type: 'text' },
      // { name: 'delete', hidden: true, label: 'Delete', type: 'checkbox', default: false }
      ];
    return !isTable ? data.filter(e => e.label) : data.filter(e => !e.hidden).map(e => ({Header: e.label ? e.label : e.labelname ,accessor: e.name,type: e.type, populate: e.populate}));
}
export default options;

