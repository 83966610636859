
import React from 'react';
import {  Button } from '@material-ui/core';

import UploadButton from './UploadButton';
import ImageListGallery from './ImageListGallery';
import CollectionsIcon from '@material-ui/icons/Collections';

const Gallery = (props) => {
    const removeImageInGallery = async (id,name) =>{
        const data = props.gallery[name].filter(e => e._id !== id);
        props.initialValues[name] = data;
        props.setGallery({[name]:data});
    }

    // merge data form and modal
    const cb = (data) => props.data?.data.push(data)
    
    return (
        <span>
            <ImageListGallery  field={props.field} actionFn={removeImageInGallery} gallery={props.gallery} setGallery={props.setGallery}/>
                
            <UploadButton name={props.field.name} gallery={props.gallery} setGallery={props.setGallery} cb={cb} multiple={true} path='gallery'/>
            <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CollectionsIcon />}
        onClick={props.handleOpen}
        style={{color:'white',marginTop:10,marginLeft:10}}
      >Open Gallery</Button>
      {props.children}
        </span>
    );
}

export default Gallery;