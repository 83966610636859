
import React, { useState } from 'react';
import ModalGallery from './ModalGallery';
import { Button } from '@material-ui/core';
import CollectionsIcon from '@material-ui/icons/Collections';



const ModalGalleryContainer = ({field,path,galleryInitialValues, setgalleryInitialValues,cbPick}) => {
   
   
    const [open, setOpen] = useState(false);
   

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);

   
 
    return (
        <span>
           
            <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CollectionsIcon />}
        onClick={handleOpen}
        style={{color:'white',marginTop:10,marginLeft:10}}
      >Open Gallery</Button>
            
                 <ModalGallery pageSize={pageSize} cbPick={cbPick} path={path} open={open} setPageSize={setPageSize}  galleryProduct={galleryInitialValues} setGalleryProduct={setgalleryInitialValues} pageIndex={pageIndex} setPageIndex={setPageIndex} handleClose={handleClose}  field={field} />
           
               
        </span>
    );
}

export default ModalGalleryContainer;