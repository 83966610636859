const options = (isTable = false) => {
  const data = [
    // { name: 'products', label: 'Produkty', type: 'autocomplete',populate:'title',fetch:'products',multiple:true },
    // { name: 'product', label: 'Produkt', type: 'autocomplete',populate:'title',fetch:'products' },
    { name: 'plaque', label: 'Plaketa', type: 'autocomplete',populate:'title',fetch:'plaques',link:'/plaques' },
  
    // { name: 'plaque', label: 'Plaketa',hidden: true, type: 'form', 
    // form: [ 
    // //   { name: 'product', label: 'Produkt', type: 'autocomplete',populate:'title',fetch:'products' },
    // //   // { name: 'price', label: 'Cena', type: 'number' ,disabled:true},
    // //   // { name: 'qty', label: 'Množstvo', type: 'number' },
    // { name: 'src', label: 'IMG', type: 'image',populate: 'src' },
    // { name: 'title', label: 'Názov', type: 'text' },
  
    // ] },
    // { name: 'productIds', label: 'ID Produktu', type: 'text' },
    { name: 'profile', label: 'Profil', type: 'autocomplete',populate:'name',fetch:'profiles',multiple:false, link:'/profiles' },
    { name: 'client', label: 'Klient',  type: 'autocomplete',populate:'fullName',fetch:'clients', link:'/clients' },
  
    { name: 'invoiceId', label: 'Číslo Faktúry', type: 'text' },
  
    // { name: 'status', label: 'Stav', type: 'text' },
  
    // { name: 'firstName', label: 'Meno', type: 'text' },
    // { name: 'lastName', label: 'Priezvisko', type: 'text' },
    // { name: 'address', label: 'Adresa', type: 'text' },
    // { name: 'phoneNumber', label: 'Telefón', type: 'text' },
    // { name: 'shippingMethod', label: 'Spôsoby Doručenia', type: 'autocomplete',populate:'title',fetch:'shipments' },
  
    // { name: 'company', label: 'Spoločnosť', type: 'autocomplete',populate:'companyName',fetch:'companies' },
    // { name: 'trackingNumber', label: 'Sledovacie Číslo', type: 'text' },
  
    // { name: 'company', label: 'Spoločnosť', type: 'text'},
    { name: 'age', label: 'Vek', type: 'number'},
  
    { name: 'priceTotal', label: 'Celková Cena', type: 'number',disabled:true,readOnly:true },
    // { name: 'qr', label: 'QR', type: 'qr' },
    { name: 'status', label: 'Stav', type: 'autocomplete',populate:'title',fetch:'status', link:'/status' },
    { name: 'paymentMethod', label: 'Spôsob Platby', type: 'autocomplete',populate:'title',fetch:'payments', link:'/payments' },
    { name: 'partner', label: 'Partner', type: 'autocomplete',populate:'promoCode',fetch:'partners', link:'/partners' },
  
    { name: 'createdAt', label: 'Vytvorené',  type: 'date', default: new Date() },
  
    // { name: 'delete', hidden: true, label: 'Zmazať', type: 'checkbox', default: false },
  
    // { name: 'isCompany', label: 'Je Spoločnosť', type: 'checkbox', default: false }
  ];
  

  return !isTable ? data.filter(e => e.label) : data.filter(e => !e.hidden).map(e => ({ Header: e.label, accessor: e.name,type: e.type, populate: e.populate,multiple:e.multiple ,form:e.form, link:e.link}));
}
export default options;