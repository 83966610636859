import React from 'react';
import {CircularProgress,Backdrop} from '@material-ui/core';

const LoadingIndicator = () => {
  return (
    <Backdrop style={{ zIndex: 9999, color: '#fff' }} open={true}>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>
    </Backdrop>
  );
};

export default LoadingIndicator;
