import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, Divider, Container, Card, CardMedia, CardContent, Button, Box, Select, MenuItem, FormControl, InputLabel, TextField, CircularProgress  } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import instance, { serverURL } from '../../helpers';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const fetchDataCategories = async (id) => {
  const response = await instance().get(`/products/${id}`);
  return response.data;
};

const fetchPaymentMethods = async () => {
  const response = await instance().get(`/payments`);
  return response.data.data;
};

function StepFour({ data, handleBack, images }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  let { id } = useParams();
  const navigate = useNavigate();
  const dataFetch = useQuery([id], () => fetchDataCategories(id), { cacheTime: 0 });
  const { data: paymentMethods } = useQuery('paymentMethods', fetchPaymentMethods, { cacheTime: 0 });

  const imageSelection = images.find((e) => e._id === data[0].selected);

  const profileDetails = data[1];
  const billingDetails = data[2];

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [promoCode, setPromoCode] = useState('');

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await instance().post(`/order/create`, {
        profile: profileDetails,
        client: billingDetails,
        productId: id,
        plaqueId: imageSelection._id,
        paymentMethodId: selectedPaymentMethod,
        promoCode: promoCode,
      });
      if (response && response.status === 201) {
        if(response?.data?.url){
          window.location.assign(response?.data?.url);
        }

        if(response?.data?.id){
          navigate(`/success?id=${response?.data?.id}`)
          // window.location.assign(response?.data?.url);
        }
        console.log('response?.data?.url',response?.data?.url);
      }
    } catch (error) {
      enqueueSnackbar(`${error?.response?.data?.msg}`, { variant: 'error', autoHideDuration: 1000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container sx={{ mb: 10 }}>
      <Typography variant="h4" component="h2" sx={{ mt: 4, mb: 2, textAlign: 'center' }}>
      {t('stepper41')}
      </Typography>
      <Divider />
      <List>
        <Typography variant="h6" sx={{ mt: 2 }}>
        {t('stepper42')}
        </Typography>
        <ListItem>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Card sx={{ maxWidth: 400, m: 1 }}>
              <CardMedia
                component="img"
                height="150"
                image={`${serverURL}/uploaded-files/${imageSelection?.src?.src}`}
                alt={imageSelection?.title}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{ textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'underline', textAlign: 'center' }}
                >
                  {imageSelection?.title}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </ListItem>
        <Divider variant="inset" component="li" />
        <Typography variant="h6" sx={{ mt: 2 }}>
        {t('stepper43')}
        </Typography>
        <ListItem>
          <ListItemText primary={`Meno a Priezvisko: ${profileDetails.name}`} secondary={`Biography: ${profileDetails.biography}`} />
        </ListItem>
        <ListItem>
          <ListItemText primary={`Dátum narodenia: ${profileDetails.birthDate}`} secondary={`Dátum úmrtia: ${profileDetails.deathDate}`} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <Typography variant="h6" sx={{ mt: 2 }}>
        {t('stepper44')}
        </Typography>
        <ListItem>
          <ListItemText primary={`Email: ${billingDetails.email}`} secondary={`Mobil: ${billingDetails.phone}`} />
        </ListItem>
        <ListItem>
          <ListItemText
            primary={`Meno a Priezvisko: ${billingDetails.fullName}`}
            secondary={`Adresa: ${billingDetails.streetAddress}, ${billingDetails.city}, ${billingDetails.postalCode}, ${billingDetails.country}`}
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemText primary={`Celková Suma: ${dataFetch?.data?.price || 0}€`} secondary={`Obdobie: ${dataFetch?.data?.age || 0} ${t('age')}`} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <Typography variant="h6" sx={{ mt: 2 }}>
        {t('stepper45')}
        </Typography>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="payment-method-label"> {t('stepper46')}</InputLabel>
          <Select
            labelId="payment-method-label"
            value={selectedPaymentMethod}
            label="Vyberte spôsob platby"
            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
          >
            {paymentMethods?.map((method) => (
              <MenuItem key={method._id} value={method._id}>
                {method.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider variant="inset" component="li" />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Promo kód:
        </Typography>
        <TextField
          label="Promo kód"
          name="promoCode"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          fullWidth
          margin="normal"
        />
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button color='secondary' onClick={handleBack} variant="outlined">
        {t('back')}
        </Button>

        
        <Button
          type="submit"
          variant="outlined"
          onClick={handleSubmit}
          disabled={!selectedPaymentMethod} // Disable the button if no payment method is selected
        >
          {loading ? <CircularProgress size={24} /> : t('pay')}
        </Button>
      </Box>
    </Container>
  );
}

export default StepFour;
