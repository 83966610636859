import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField,Checkbox, Button, Paper, Typography,Box, Modal, Grid } from '@material-ui/core';
import instance,{ serverURL } from '../helpers';

import GalleryContainer from './gallery/GalleryContainer';
import UploadButton from './gallery/UploadButton';
import ModalGalleryContainer from './gallery/ModalGalleryContainer';
import AutoCompleteContainerForm from './admin/inputs/AutoCompleteContainerForm';
import { AddCircleOutline,Close } from '@material-ui/icons';
import { Scanner } from '@yudiel/react-qr-scanner';

// formating date format MM/DD/YYYY
function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}


const GenericForm = ({ initialValues, onSubmit, fields, title }) => {
  const [titleScan, setTitleScan] = useState('');

  const [gallery, setGallery] = useState({});
  const [refresh, setRefresh] = useState(1);

  // initializing default value to initialValues
  if(Object.keys(initialValues).length === 0){
    fields.forEach(element => {
      if(element.default !== undefined){
        initialValues[element.name] = element.default;
      }
    }); 
  }

  // formating Date type
  fields.filter(e => e.type === 'date').forEach(element => initialValues[element.name] = formatDate(new Date(initialValues[element.name])));
  
  // formating Image
  fields.filter(e => e.type === 'image').forEach(element => {
      if(gallery && !gallery[element.name] && initialValues[element.name]){
        setGallery({[element.name]:initialValues[element.name]})
      }
  });


  
  const allTypes = (field,index,initialValues,form,initialValuesFull) =>{
  
    if(field.type === 'scan') {
      return <div style={{height:256,width:256,marginLeft:'auto',marginRight:'auto',marginBottom:50}}><Scanner onScan={async (result) => {
        
        const segments = new URL(result[0]?.rawValue).pathname.split('/');
        const id = segments[segments.length - 1];
        initialValues[field.name] = id
        
        const response = await instance(true).get(`/qr-link/${id}`);
        if (response && response.status === 200) {
          initialValues[field.name] = id
          setTitleScan(response?.data?.title)
        }

      }} />
      {`${titleScan || initialValues[field.name]?.title}`}</div>;
    }

    if(field.type === 'autocomplete') {
      return(<AutoCompleteContainerForm 
        field={field} initialValues={initialValues} multiple={field.multiple}
        cb={(value,name) => {
        initialValues[name] = value;              
      }} 
      />)
    }
    if(field.type === 'gallery') {
      return(<GalleryContainer field={field} initialValues={initialValues} path='gallery'/>)
    }
  if(field.type === 'image') {
    // TODO presunut do componenty zvlast 
    return (  
      
    <div key={`${index}-${field.name}`} style={{ marginBottom: '20px' }}>
          <Paper style={{ padding: 5, maxWidth: 400,  }}>
          <Box >


      {gallery[field.name] && gallery[field.name][field.populate].split('.')[1] === 'svg' ? <div className="list-icons" dangerouslySetInnerHTML={{ __html: gallery[field.name].data || '' }}></div> : gallery[field.name] && <img height={400} src={`${serverURL}/uploaded-files/${gallery && gallery[field.name] && gallery[field.name][field.populate]}`} />}
      <UploadButton name={field.name} gallery={gallery} setGallery={setGallery} cb={ (data) => initialValues[field.name] = data } path='files'/>
      <ModalGalleryContainer cbPick={(data) => initialValues[field.name] = data } field={field} galleryInitialValues={gallery} setgalleryInitialValues={setGallery} path='files'></ModalGalleryContainer>
      
    </Box>
    </Paper>
    </div>);
  }

  return (
    <div key={`${index}-${field.name}`} style={{ marginBottom: '20px' }}>
      
      {field.type !== 'checkbox' ? '' : field.label}
      {form ? <Field
        as={field.type !== 'checkbox' ? TextField : Checkbox}
        variant={field.type !== 'checkbox' ? "outlined" : undefined}
        defaultValue={initialValues[field.name] || ''}
        onChange={e => initialValuesFull[form].find(e=> e._id === initialValues._id)[field.name] = e.target.value}
        {...field}
        fullWidth
      />:
      <Field
        as={field.type !== 'checkbox' ? TextField : Checkbox}
        variant={field.type !== 'checkbox' ? "outlined" : undefined}
        {...field}
        fullWidth
      />}
    </div>
  )
  }

  const listFields = (fields,initialValues,isForm = false,initialValuesFull = false) => {
    return fields.map((field,index) => {

      if(field.type === 'qr'){
        return ( <Paper style={{ padding: 5, marginBottom:40 }}> <Typography variant="h6" style={{ paddingBottom: '15px' }}>
        {field.label} </Typography><img width={320} loading="lazy" src={initialValues[field.name]} style={{marginBotton:20}}/></Paper>)
      } 

       if(field.type === 'form') {
        return (
          
            <Paper style={{ padding: 5, marginBottom:40 }}>
              <Typography variant="h6" style={{ paddingBottom: '15px' }}>
                {field.label} 
                <AddCircleOutline style={{float:'right',cursor:'pointer'}} onClick={() => {
                  const add = {}

                   field.form.forEach(e => {
                    add[e.name] = e.type === 'number' ? 1 : e.type === 'autocomplete' && e.populate ? {[e.populate]: ''} : e.type === 'autocomplete' && !e.populate ? {} : e.type === 'gallery' ? [] : e.type === 'date' ? new Date() : e.type ==='checkbox' ? false : e.type === 'image' ? {} : '';
                  })
                  initialValues[field.name].push(add)
                  setRefresh(refresh+1)

                  }}/>
              </Typography>
              
            <Box >
              
            <Grid  spacing={6}  container>
              
            {field.multiple ? initialValues[field.name]?.map((element,index2) => (<Grid item xl={5} xs={5} md={5}> 
            <Close style={{float:'right',cursor:'pointer',position:'relative',top:-10,left:5}} onClick={() => {

              // TODO not working,remove last all time
              initialValues[field.name] = initialValues[field.name].filter((e,i) => i !== index2)
              setRefresh(refresh+1)

            }}/> 
            {listFields(field.form,element,field.name,initialValues)}</Grid>)) :
            <Grid item xl={5} xs={5} md={5}>{listFields(field.form,{[field.name]:initialValues[field.name]},field.name,initialValues)}</Grid>}
            
            </Grid>
            </Box>
            </Paper>
         
          )
     
      }
      return allTypes(field,index,initialValues,isForm,initialValuesFull)
})
  }
  return (
    <Paper style={{ padding: '25px' }}>
       <div style={{ display: 'flex',  justifyContent: 'space-between',alignItems: 'center', padding: '10px' }}>
            <Typography variant="h6" style={{ paddingBottom: '15px' }}>{title}</Typography>
        </div>
        
    <Formik initialValues={initialValues} onSubmit={(data,func)=>onSubmit({...initialValues,...data,...gallery},func)} >
     
      <Form>
     

        {refresh && listFields(fields,initialValues)}
        <Button type="submit" variant="contained" color="primary">
          Uložiť
        </Button>
      </Form>
    </Formik>
    </Paper>

  );
};

export default GenericForm;
