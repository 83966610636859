import React, { useState } from 'react';
import { Container, TextField, Button, Box, Typography, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import instance from '../helpers';
import { useTranslation } from 'react-i18next';

function PartnersPage() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    fullName: '',
    streetAddress: '',
    postalCode: '',
    phone: '',
    email: '',
    city: '',
    country: '',
    ico: '',
    dic: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance().post('/partners', formData);
      if (response && response.status === 201) {
        enqueueSnackbar('Údaje boli úspešne odoslané.', { variant: 'success', autoHideDuration: 1000 });
        setFormData({
          fullName: '',
          streetAddress: '',
          postalCode: '',
          phone: '',
          email: '',
          city: '',
          country: '',
          ico: '',
          dic: '',
        });
      }
    } catch (error) {
      console.log('error',error);
      enqueueSnackbar(`Chyba pri odosielaní údajov: ${error?.response?.data?.msg}`, { variant: 'error', autoHideDuration: 1000 });
    }
  };

  return (
    <Container sx={{ mt: 10}}>
      <Typography variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
        {t('registerParnters')}
      </Typography>
      <Divider />
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
        
          label="Meno a Priezvisko / Nazov Firmy"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
         <TextField
          label="IČO"
          name="ico"
          value={formData.ico}
          onChange={handleChange}
          fullWidth
          margin="normal"
          
        />
             <TextField
          label="DIČ"
          name="dic"
          value={formData.dic}
          onChange={handleChange}
          fullWidth
          margin="normal"
          
        />
        <TextField
          label="Ulica a číslo"
          name="streetAddress"
          value={formData.streetAddress}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="PSČ"
          name="postalCode"
          value={formData.postalCode}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Telefón"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Mesto"
          name="city"
          value={formData.city}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Krajina"
          name="country"
          value={formData.country}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }} >
          <Button type="submit" variant="outlined">
            {t('send')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default PartnersPage;
