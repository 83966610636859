import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import CheckoutStepper from './components/Stepper';

import { useTranslation } from 'react-i18next';

function Order() {
  const { t } = useTranslation();

  return (
    <Container
      component="main"
      // maxWidth="md"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mt: { xs: 4, md: '8%' },
      }}
    >
      <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom textAlign="center">
          {t('orderTitle')}
        </Typography>
        <CheckoutStepper />
      </Box>
    </Container>
  );
}

export default Order;
