import React, { useContext } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import instance from '../../helpers';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// Validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string()
    .email('Zadajte platný e-mail')
    .required('Vyžaduje sa e-mail'),
    name: Yup.string()
    .required('Meno a priezvisko je povinné')
    .min(3, 'Meno a priezvisko by mali mať aspoň 3 znaky'),
  // password: Yup.string()
  //   .required('Vyžaduje sa heslo')
  //   .min(8, 'Heslo by malo mať minimálne 8 znakov'),
});

function InviteModal({ open, handleClose }) {
  const { t } = useTranslation();

  const { login, register } = useContext(AuthContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogin = async (values) => {
    const response = await instance().post(`/invite`, values);
   if(response && response.status === 201){
      enqueueSnackbar('Invite successfully', { variant: 'success', autoHideDuration: 1000 });

      // navigate(`/thank-you`);
   }
    // const status = await login(values);
    // if(status){
    //   navigate('/profile')
    // }
  }
  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle style={{background:'#fef7f0'}}>{t('inviteFamily')}</DialogTitle>
      <Formik
        initialValues={{ email: '', name: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          // console.log(values);
          actions.setSubmitting(false);
          handleClose(); // Close modal on successful login
          handleLogin(values)
        }}
      >
        {({ errors, touched }) => (
          <Form style={{background:'#fef7f0'}}>
            <DialogContent>
            <Field
                as={TextField}
                
                name="name" 
                label="Meno a priezvisko"
                fullWidth
                margin="normal"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <Field
                as={TextField}
                name="email"
                label="Email"
                fullWidth
                margin="normal"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                className="emailLogin"
              />
             

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined" color="secondary">
              {t('canceled')}
              </Button>
              <Button type="submit" color="primary" variant="outlined">
              {t('ivnite')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default InviteModal;
