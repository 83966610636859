const options = (isTable = false) =>{
      const data = 
      [
        { name: 'title', label: 'Názov', type: 'text' },
        { name: 'price', label: 'Cena v %', type: 'number' },
        { name: 'partners', label: 'Partneri', type: 'autocomplete', populate: 'promoCode', fetch: 'partners', multiple: true, link: '/partners' },
      
        { name: 'active', label: 'Aktívne', type: 'checkbox', default: false }
      ];
  
      return !isTable ? data.filter(e => e.label) : data.filter(e => !e.hidden).map(e => ({ Header: e.label, accessor: e.name,type: e.type, populate: e.populate,multiple:e.multiple ,form:e.form,search:e.search,link:e.link }));
    }
export default options;

