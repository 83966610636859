import React, { useState, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, TextField, Paper, Tabs, Tab, Box } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContextAdmin';
import { Navigate } from 'react-router-dom';

const AuthPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { login, register } = useContext(AuthContext);
  const { isAuthenticated } = useContext(AuthContext);

   // redirect check token login
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleLogin = async (values) => {
    const status = await login(values);
    if(status){
      navigate('/dashboard')
    }
  }

  const handleRegister = async (values) => {
    const status = await register(values);
    if(status){
      navigate('/dashboard')
    }
  };

 

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <Paper style={{ padding: 20, maxWidth: 400, margin: 'auto' }}>
      <Tabs value={activeTab} onChange={handleTabChange} centered>
        <Tab label="Login" />
        <Tab label="Register" />
      </Tabs>
      {activeTab === 0 && (
        <Box mt={3}>
          <Formik initialValues={{ email: '', password: '' }} onSubmit={handleLogin}>
            <Form>
              <Field as={TextField} name="email" type="email" label="Email" fullWidth />
              <br /><br />
              <Field as={TextField} name="password" type="password" label="Password" fullWidth />
              <br /><br />
              <Button type="submit" variant="contained" color="primary" fullWidth>Login</Button>
            </Form>
          </Formik>
        </Box>
      )}
      {activeTab === 1 && (
        <Box mt={3}>
          <Formik initialValues={{ email: '', password: '' ,name:'admin'}} onSubmit={handleRegister}>
            <Form>
              <Field as={TextField} name="name" type="text" label="Name" fullWidth />
              <br /><br />
              <Field as={TextField} name="email" type="email" label="Email" fullWidth />
              <br /><br />
              <Field as={TextField} name="password" type="password" label="Password" fullWidth />
              <br /><br />
              <Button type="submit" variant="contained" color="primary" fullWidth>Register</Button>
            </Form>
          </Formik>
        </Box>
      )}
    </Paper>
    </div>
  );
};

export default AuthPage;
