import React, { useState } from 'react';
import GenericForm from '../components/GenericForm';
import AdminLayout from '../layouts/AdminLayout';
import instance from '../helpers';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

const CreatePage = ({name, options}) => {
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = { };
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {                                               // fix with other types inputs (Autocomplete) //  TODO upravit aj editPage a aj ostatne inputs (Image,Gallery)
     const response = await instance(true).post(`/${name}`, {...values, ...initialValues});
     if(response && response.status === 201){
        navigate(`/${name}`);
     }
    } catch (error) {
      enqueueSnackbar(`Error submitting: ${error}`, { variant: 'error', autoHideDuration: 1000 });
    }
  };

  return (
    <AdminLayout>
        <div style={{ margin: '20px' }}>
            <GenericForm initialValues={initialValues} onSubmit={handleSubmit} fields={options()} title={`CREATE ${name.toUpperCase()}`}/>
        </div>
    </AdminLayout>
  );
};

export default CreatePage;
