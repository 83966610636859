import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { AuthContext } from '../AuthContext';
import { Navigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import instance from '../helpers';
import StandardImageList from './components/ImageList';

import LoadingIndicator from '../components/LoadingIndicator';
import { useTranslation } from 'react-i18next';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // mesiace sú indexované od 0
  const year = date.getFullYear();
  return `${day}. ${month}. ${year}`;
}



const fetchDataCategories = async (id)=>{
  const response = await instance().get(`/profile/${id}`);
  return response.data;
}


const fetchDataStatus = async ()=>{
  const response = await instance().get(`/status-profile`);
  return response;
}
function Profiles() {
  const { t } = useTranslation();

  const { id } = useParams();
  const [statusIsOK, setStatusIsOK] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { data, status } = useQuery([], () => fetchDataCategories(id),{cacheTime:0});
  const statusArr = useQuery('statusData', fetchDataStatus,{cacheTime:0});

  if (status === 'error') {
    enqueueSnackbar(`Error fetching data /profile`, { variant: 'error', autoHideDuration: 1000 });
  };
  const { verifyToken } = useContext(AuthContext);

  // const items = data && data.data || []
  useEffect(() =>{ verifyToken()},[]);


  useEffect(() =>{ 
  
    if(statusArr?.data?.data?.data && data?.status?._id){
      const index = statusArr?.data?.data?.data.findIndex(e=>e._id === data?.status?._id)
      if(index === 1 && statusIsOK === false){
        setStatusIsOK(true);
      }    
    }
  
  },[statusArr]);

 
  if (status === 'loading') return <LoadingIndicator/>;

  if (statusArr?.status === 'loading') return <LoadingIndicator/>;


//  const { isAuthenticated, isLoading, verifyToken } = useContext(AuthContext);
//  useEffect(() =>{ verifyToken()},[]);

//  if (!isAuthenticated) {
//    return <Navigate to="/" replace />;
//  }


  return (statusIsOK === false) ? <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
  <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h2" component="h1" gutterBottom>
      {t('profileApproved1')}
      </Typography>
      <Typography variant="body1">
      {t('profileApproved2')}
      </Typography>
    </Box>
  </Container> : (
    <Container component="main" sx={{ marginTop: '100px'}}>
    <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* <Typography variant="h2" component="h1" gutterBottom>
            Profile
        </Typography> */}
        <Typography variant="h3" sx={{ marginBottom: '10px'}}>
        {data?.name || ''}

        </Typography>

        <Typography variant="h4" sx={{ marginBottom: '20px'}}>
        ({data && data.birthDate && data.deathDate ? `${formatDate(data.birthDate)} - ${formatDate(data.deathDate)}` : ''})
        </Typography>

        <Typography variant="body1" sx={{ marginBottom: '40px'}}>
        {data?.biography || ''}

        </Typography>
        <StandardImageList itemData={data?.gallery || []}></StandardImageList>

      </Box>
    </Container>
  );
}

export default Profiles;
