// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MainAppBar from './pages/components/AppBar';
import { AuthProvider } from './AuthContext';
import { AuthProvider as  AuthProviderAdmin} from './AuthContextAdmin';

import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';

import './i18n'; // Pridaj tento import

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#35474E',
      dark: '#000',
      contrastText: '#35474E',
    },
    secondary: {
      main: '#B3D5E7',  // Sekundárna farba
    },
    text: {
      primary: '#35474E',
      secondary: '#B3D5E7',  // Sekundárna farba textu
    },
  },
  
  typography: {
    // fontFamily: 'monospace, Arial, sans-serif',
    fontFamily: '"Outfit", Arial, sans-serif',
  },
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <SnackbarProvider maxSnack={3}>
    <QueryClientProvider client={queryClient}>

    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <AuthProvider>
      <AuthProviderAdmin>
      <main>
        <MainAppBar/>
    <div className="App" >
    
          <App />
        
      </div>
      </main>
      </AuthProviderAdmin>
      </AuthProvider>
      </ThemeProvider>
      </QueryClientProvider>
      </SnackbarProvider>
    </BrowserRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
