import React, { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link,useLocation,useNavigate  } from 'react-router-dom';
import LoginModal from './LoginModal'; // Import the LoginModal component
import { AuthContext } from '../../AuthContext';
import PasswordModal from './PasswordModal';
import InviteModal from './InviteModal';
import Logo from './logo.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import LanguageSwitcher from './LanguageSwitcher'; // Import LanguageSwitcher komponenty


import { useTranslation } from 'react-i18next';

export default function MainAppBar() {
  const navigate = useNavigate();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [edit, setEdit] = useState(false);

  const [open, setOpen] = useState(false);
  const [openPass, setOpenPass] = useState(token ? true: false);
  const [openInv, setOpenInv] = useState(false);

  const { isAuthenticated,logout } = useContext(AuthContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  

  const handleOpenPass = () => setOpenPass(true);
  const handleClosePass = () => setOpenPass(false);

  const handleOpenInv = () => setOpenInv(true);
  const handleCloseInv = () => setOpenInv(false);


  // TODO
  const handleLogout = () => logout();




  const { i18n ,t} = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className='app-bar' color="transparent" position="fixed">
        <Toolbar >
        {/* <Button onClick={() => changeLanguage('sk')}>
          <img src={''} alt="SK" width="30" height="20" />
        </Button>
        <Button onClick={() => changeLanguage('cs')}>
          <img src={''} alt="CZ" width="30" height="20" />
        </Button>
         */}
            <img style={{cursor:'pointer'}} onClick={() => navigate(!isAuthenticated ? '/' : '/profile')} src={Logo} height={isMobile ? 27 : 50}></img> 
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
               {/* <Link to={'/'}> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1,marginLeft:isMobile ? 1 : 2 ,fontSize: isMobile ? 12 : "1rem",fontWeight:900}}>
            <span style={{fontSize: isMobile ? '9px': '1rem', cursor:'pointer',textTransform: 'uppercase',letterSpacing:'5px'}}  onClick={() => navigate(!isAuthenticated ? '/' : '/profile')}>
          Navždy.<span style={{color:'#B3D5E7'}}>online</span>
          </span>
          </Typography>
          {/* </Link> */}
          {/* {location.pathname === '/profile' && isAuthenticated && <Button color="inherit" onClick={() => setEdit(!edit)}>{edit ? 'Edit' : 'Uloziť'}</Button>} */}
          {location.pathname === '/profile' && isAuthenticated && <Button color="inherit"  onClick={handleOpenInv} >{t('invite')}</Button>}

          <Button sx={{fontSize:isMobile ? 10 : "0.8rem" ,marginLeft:1}} color="inherit"  onClick={!isAuthenticated ? handleOpen : handleLogout}>{!isAuthenticated ?  t('login') : t('logout') }</Button>
          <Button sx={{fontSize:isMobile ? 10 : "0.8rem"  }} color="inherit"  onClick={() => navigate('/partner')}>{t("register-partner")}</Button>
          <LanguageSwitcher />

          <LoginModal open={open} handleClose={handleClose} />
          <PasswordModal open={openPass} handleClose={handleClosePass} />
          <InviteModal open={openInv} handleClose={handleCloseInv} />

        </Toolbar>
      </AppBar>
    </Box>
  );
}