import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';

import textfoto from './textfoto.jpg';
import { useTranslation } from 'react-i18next';

export default function Info() {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, p: { xs: 2, md: 4 } }}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={4} my={{ xs: 2, md: 10 }}>
          <Box
            component="img"
            src={textfoto}
            alt="Text Foto"
            sx={{ width: '100%', borderRadius: 2, boxShadow: 3 }}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Box sx={{ p: { xs: 2, md: 5 } }}>
            <Typography variant="h4" component="h3" gutterBottom>
              {t('info1')}
            </Typography>
            <Typography variant="body1" gutterBottom color="textPrimary">
            {t('info2')}

            </Typography>
            <Divider className="bold-divider" sx={{ my: 2 }} />

            <Typography variant="body1" paragraph className="top-50">
            {t('info31')}
            {t('info32')}
            {t('info33')}

            </Typography>

            <Typography variant="body1" paragraph className="top-50">
            {t('info4')} 
            </Typography>

            <Typography variant="body1" paragraph className="top-50">
            {t('info5')}  
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
