// import logo from './logo.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.css';
import SimpleSlider from './components/Slider';
import Info from './components/Info';
import Grid from '@mui/material/Unstable_Grid2';
import Service from './components/Service';
import Slider2 from './components/Slider2';
import Info2 from './components/Info2';
import Divider from '@mui/material/Divider';
import { useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom";
// import LoadingIndicator from "../../../react/src/components/LoadingIndicator";



function Home() {

 const { isAuthenticated, isLoading, verifyToken } = useContext(AuthContext);
 useEffect(() =>{ verifyToken()},[]);

 if (isAuthenticated) {
   return <Navigate to="/profile" replace />;
 }

  return (
    <div >
        <Grid container md={12}>
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: '100%', width: '100%',marginTop:64 }}>
          <iframe

            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            src="https://www.youtube.com/embed/cbW_G_BGd3s?autoplay=1&mute=1&vq=hd1080&loop=1&playlist=cbW_G_BGd3s"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Grid>
    <SimpleSlider />
     <Info />
     <Slider2 />
  
     <Service />
   
    <Info2 />
    <Grid container  md={12} sm={12}>
      <Grid mdOffset={0.5} smOffset={0.5} md={11} sm={11}>
        <Divider className='bold-divider' />
      </Grid>
    </Grid>
  </div>

  );
}

export default Home;
