// V Layout.js
import React, { useContext, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, CssBaseline } from '@material-ui/core';
// import { Inbox as InboxIcon } from '@material-ui/icons';

import { 
  Inbox as InboxIcon, 
  ListAlt as OrdersIcon,
  AccountCircle as ProfilesIcon,
  People as ClientsIcon,
  // QrCode as QrIcon,
  LocalOffer as DiscountsIcon,
  ShoppingCart as ProductsIcon,
  Assignment as PlaquesIcon,
  CheckCircle as StatusIcon,
  Face as StatusProfileIcon,
  Payment as PaymentsIcon,
  Person as PartnersIcon
} from '@material-ui/icons'


import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { AuthContext } from '../AuthContextAdmin';
import instance, { serverURL } from '../helpers';
import { useQuery } from 'react-query';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  chartsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}));

const fetchDataProfiles = async (id) => {
  const response = await instance(true).get(`/profiles?limit=*`);
  return response.data;
};

const fetchDataOrders = async (id) => {
  const response = await instance(true).get(`/orders?limit=*`);
  return response.data;
};

const Layout = ({ children }) => {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const queryProfiles = useQuery('dataFetchProfiles', () => fetchDataProfiles(), { enabled: false, cacheTime: 0 });
  const queryOrders = useQuery('dataFetchOrders', () => fetchDataOrders(), { enabled: false, cacheTime: 0 });

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      queryProfiles.refetch();
      queryOrders.refetch();
    }
  }, [location.pathname, queryProfiles.refetch, queryOrders.refetch]);

  const handleMenu = (name) => {
    navigate(`/${name}`);
  };

  const handleLogout = () => logout();

  const data = [
    { name: 'Profiles', value: queryProfiles?.data?.totalCount || 0 },
    { name: 'Pending Orders', value: queryOrders?.data?.data?.filter(e => e.status && e.status.title && e.status.title !== 'done').length || 0 },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} onClick={() => navigate('/dashboard')}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            CRM NAVZDYONLINE
          </Typography>
          <Box flexGrow={1} />
          <Button color="inherit" variant="outlined" onClick={handleLogout}>Odhlásiť</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List>
          <ListItem button onClick={() => handleMenu('orders')}>
            <ListItemIcon><OrdersIcon /></ListItemIcon>
            <ListItemText primary="Objednávky" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('Profiles')}>
            <ListItemIcon><ProfilesIcon /></ListItemIcon>
            <ListItemText primary="Profili" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('Clients')}>
            <ListItemIcon><ClientsIcon /></ListItemIcon>
            <ListItemText primary="Klienti" />
          </ListItem>
            <ListItem button onClick={() => handleMenu('Partners')}>
            <ListItemIcon><PartnersIcon /></ListItemIcon>
            <ListItemText primary="Partneri" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('qr')}>
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText primary="QR Kódy" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('discounts')}>
            <ListItemIcon><DiscountsIcon /></ListItemIcon>
            <ListItemText primary="Zľava" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('products')}>
            <ListItemIcon><ProductsIcon /></ListItemIcon>
            <ListItemText primary="Produkty" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('Plaques')}>
            <ListItemIcon><PlaquesIcon /></ListItemIcon>
            <ListItemText primary="Plakety" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('Status')}>
            <ListItemIcon><StatusIcon /></ListItemIcon>
            <ListItemText primary="Statusy" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('status-profile')}>
            <ListItemIcon><StatusProfileIcon /></ListItemIcon>
            <ListItemText primary="Statusy Profilov" />
          </ListItem>
          <ListItem button onClick={() => handleMenu('Payments')}>
            <ListItemIcon><PaymentsIcon /></ListItemIcon>
            <ListItemText primary="Platobné statusy" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children ? (
          children
        ) : (
          <div className={classes.chartsContainer}>
            <ResponsiveContainer width="80%" height={400}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#1f1f1f">
                  <LabelList dataKey="value" position="center" />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
      </main>
    </div>
  );
};

export default Layout;
