
import React from 'react';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import instance from '../../../helpers';
import AutoCompleteFormMultiple from './AutoCompleteFormMultiple';
import AutoCompleteForm from './AutoCompleteForm';


const fetchData = async (path)=>{
    const response = await instance(true).get(`/${path}?limit=*`);
    return response.data;
  }
const AutoCompleteContainerForm = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, status } = useQuery([props.field.fetch], () => fetchData(props.field.fetch));
  if (status === 'error') {
    enqueueSnackbar(`Error fetching data /${props.field.fetch}`, { variant: 'error', autoHideDuration: 1000 });
  };


    return (
        data && data.data ? props.multiple ? <AutoCompleteFormMultiple data={data.data} field={props.field} initialValues={props.initialValues} cb={props.cb}/> : <AutoCompleteForm data={data.data} field={props.field} initialValues={props.initialValues} cb={props.cb}/> : ''
    );
}

export default AutoCompleteContainerForm;