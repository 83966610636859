import React, { useContext, useState } from 'react';
import { Container, Typography, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import instance from '../helpers';
import { useQuery } from 'react-query';
import LoadingIndicator from '../components/LoadingIndicator';
import { AuthContext } from '../AuthContext';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const fetchData = async () => {
  const response = await instance().get(`/members`);
  return response.data;
};

function MembersPage() {
  const { t } = useTranslation();

  const { data, status } = useQuery([], () => fetchData(), { cacheTime: 0 });
  const { isAuthenticated } = useContext(AuthContext);


  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (status === 'loading') return <LoadingIndicator />;
  if (status === 'error') {
    enqueueSnackbar('Error', { variant: 'error', autoHideDuration: 1000 });
    return null;
  }

  return (
    <Container sx={{ mt: 10 }}>
      <Typography variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
        {t('members1')}
      </Typography>
      <Typography variant="h6" component="h6" sx={{ mb: 2, textAlign: 'center' }}>
      {t('members2')} "{data.partner?.promoCode}"
      </Typography>
      <Divider />
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{t('typeQr')}</TableCell>
                <TableCell align="right">{t('totalPrice')}</TableCell>
                <TableCell align="center">{t('time')}</TableCell>
                <TableCell>{t('creted')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.clients?.map((row) => (
                <TableRow key={row.client}>
                  <TableCell component="th" scope="row">
                    {row.client}
                  </TableCell>
                  <TableCell>{row.plaque}</TableCell>
                  <TableCell align="right">{row.priceTotal} €</TableCell>
                  <TableCell align="center">{row.age} {t('age')}</TableCell>
                  <TableCell>{new Date(row.createdAt).toLocaleDateString('sk')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
}

export default MembersPage;
