
import React from 'react';
import { ImageList, ImageListItem, ImageListItemBar, IconButton, Grid } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import { serverURL } from '../../helpers';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ImageListGallery = (props) => {
   
    // const removeImageInGallery = async (id,name) =>{
    //     const data = props.gallery[name].filter(e => e._id !== id);
    //     props.initialValues[name] = data;
    //     props.setGallery({[name]:data});
    // }
  
      
    return (
        <span>
          {props.children}
          <ImageList  cols={5} rowHeight={164} >
            {props.gallery && props.gallery[props.field.name] && Array.isArray(props.gallery[props.field.name]) && props.gallery[props.field.name].map((element,index2) => ( 
              <ImageListItem key={`${index2}-${element._id}`} style={{textAlign:'center'}}>
                {element[props.field.populate].split('.')[1] === 'svg' ? <span className="list-icons" style={{ position: 'relative',top: '20%'}} dangerouslySetInnerHTML={{ __html: element.data || '' }}></span> : <img loading="lazy"  src={`${serverURL}/uploaded-files/${element[props.field.populate]}`} />}
                
                <ImageListItemBar
                  // title={item.title}
                  // subtitle={item.author}
                  actionIcon={
                          <Grid container spacing={10}>
  {props.pickImageInGallery && <Grid item xs={6}>
  <IconButton
    style={{cursor:`${
      
      Array.isArray(props.galleryProduct[props.field.name]) ? 
      props.galleryProduct[props.field.name].find(ee=> ee._id === element._id)?._id ? 'default' : 'pointer' : 
      props.galleryProduct[props.field.name] && props.galleryProduct[props.field.name]._id && props.galleryProduct[props.field.name]._id === element._id ? 'default' : 'pointer'
    }`}}
                      sx={{ color: 'rgba(255, 255, 255, 0.54)'}}
                      aria-label={`delete`}
                      onClick={() => 
                        Array.isArray(props.galleryProduct[props.field.name]) ?
                        props.galleryProduct[props.field.name].find(ee=> ee._id === element._id)?._id ? '' : props.pickImageInGallery(element._id,props.field.name) :
                        props.galleryProduct[props.field.name] && props.galleryProduct[props.field.name]._id && props.galleryProduct[props.field.name]._id === element._id ? '' : props.pickImageInGallery(element._id,props.field.name)
                      }
                    >
                     {
                     Array.isArray(props.galleryProduct[props.field.name]) ?
                     props.galleryProduct[props.field.name].find(ee=> ee._id === element._id)?._id ? <CheckCircleIcon /> : <CheckCircleOutlineIcon /> :
                     props.galleryProduct[props.field.name] && props.galleryProduct[props.field.name]._id && props.galleryProduct[props.field.name]._id === element._id ? <CheckCircleIcon /> : <CheckCircleOutlineIcon /> 
                    }
                    </IconButton>
  </Grid>}
  <Grid item xs={6}>
  <IconButton
                      sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                      aria-label={`delete`}
                      onClick={() => props.actionFn(element._id,props.field.name)}
                    >
                      <Delete />
                    </IconButton>
  </Grid>
  </Grid>

                   
                  
                  }
              />
              </ImageListItem >
            ))}
          </ImageList>
        </span>
    );
}

export default ImageListGallery;