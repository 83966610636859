import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, TextField, Button, Tabs, Tab } from '@mui/material';
import { AuthContext } from '../AuthContext';
import { Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import instance from '../helpers';
import StandardImageList from './components/ImageList';
import UploadButton from '../components/gallery/UploadButton';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}. ${month}. ${year}`;
}

function formatDateForInput(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const fetchDataCategories = async () => {
  const response = await instance().get(`/profile`);
  return response.data;
};

function Profile() {
  const [currentTab, setCurrentTab] = useState(0);
  const [edit, setEdit] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [gallery, setGallery] = useState({gallery:[]});
  const [profileData, setProfileData] = useState({
    name: '',
    birthDate: '',
    deathDate: '',
    biography: '',
  });

  const { enqueueSnackbar } = useSnackbar();
  const { data, status } = useQuery([], fetchDataCategories, { cacheTime: 0 });
  const { isAuthenticated, isLoading, verifyToken, role } = useContext(AuthContext);

  useEffect(() => { verifyToken() 
  
  
  
  }, []);
  
  useEffect(() => {
    if (data && data.profiles) {
      setProfiles(data.profiles);
      setProfileData({
        name: data.profiles[0]?.name || '',
        birthDate: data.profiles[0]?.birthDate ? formatDateForInput(data.profiles[0]?.birthDate) : '',
        deathDate: data.profiles[0]?.deathDate ? formatDateForInput(data.profiles[0]?.deathDate) : '',
        biography: data.profiles[0]?.biography || '',
      });
      setGallery({ gallery: data.profiles[0]?.gallery });
    }
  }, [data]);

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  if (role === 'partner') {
    return <Navigate to="/members" replace />;
  }


  if (status === 'error') {
    enqueueSnackbar(`Error fetching data /profiles`, { variant: 'error', autoHideDuration: 1000 });
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    const selectedProfile = profiles[newValue];
    setProfileData({
      name: selectedProfile.name || '',
      birthDate: selectedProfile.birthDate ? formatDateForInput(selectedProfile.birthDate) : '',
      deathDate: selectedProfile.deathDate ? formatDateForInput(selectedProfile.deathDate) : '',
      biography: selectedProfile.biography || '',
    });
    setGallery({ gallery: selectedProfile.gallery });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const handleSubmit = async (id) => {
    const formattedData = {
      ...profileData,
      ...gallery,
      birthDate: new Date(profileData.birthDate).toISOString(),
      deathDate: new Date(profileData.deathDate).toISOString(),
    };

    try {
      await instance().put(`/profile/${id}`, formattedData);
      enqueueSnackbar('Profile updated successfully', { variant: 'success', autoHideDuration: 1000 });
      setEdit(false);
    } catch (error) {
      enqueueSnackbar(`Error updating profile`, { variant: 'error', autoHideDuration: 1000 });
    }
  };

  const cb = (data) => {};
  console.log('role22',role,isAuthenticated);

  return (
    <Container component="main" sx={{ marginTop: '100px' }}>
      <Tabs value={currentTab} onChange={handleTabChange}>
        {profiles.map((profile, index) => (
          <Tab key={index} label={profile.name} />
        ))}
      </Tabs>
      <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {!edit ? (
          <>
            <Typography variant="h3" sx={{ marginTop: '10px' }}>
              {profileData.name}
            </Typography>
            <Typography variant="h4" sx={{ marginBottom: '20px' }}>
              {profileData.birthDate && profileData.deathDate ? `${formatDate(profileData.birthDate)} - ${formatDate(profileData.deathDate)}` : ''}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: '40px' }}>
              {profileData.biography}
            </Typography>
            <StandardImageList itemData={gallery.gallery} setGallery={setGallery} />
          </>
        ) : (
          <>
            <TextField
              label="Name"
              name="name"
              value={profileData.name}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: '20px' }}
            />
            <TextField
              label="Birth Date"
              name="birthDate"
              type="date"
              value={profileData.birthDate}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: '20px' }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Death Date"
              name="deathDate"
              type="date"
              value={profileData.deathDate}
              onChange={handleInputChange}
              fullWidth
              sx={{ marginBottom: '20px' }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Biography"
              name="biography"
              value={profileData.biography}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
              sx={{ marginBottom: '20px' }}
            />
            <StandardImageList edit={edit} itemData={gallery.gallery} setGallery={setGallery} />
            {edit && <UploadButton name={'gallery'} gallery={gallery} setGallery={setGallery} cb={cb} multiple={true} path='gallery' left={true} />}
          </>
        )}
        {data && data.user && data.user.creator && (
          <Button variant="outlined"  color="inherit" onClick={() => (edit ? handleSubmit(profiles[currentTab]._id) : setEdit(!edit))} style={{ float: 'right',marginTop:10 }}>
            {!edit ? 'Upraviť' : 'Uložiť'}
          </Button>
        )}
      </Box>
    </Container>
  );
}

export default Profile;
