import React, { useContext ,useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContextAdmin';
import LoadingIndicator from './components/LoadingIndicator';


const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, verifyToken } = useContext(AuthContext);
  useEffect(() =>{ verifyToken()},[]);
  if (isLoading) {
    return <LoadingIndicator/>;
  }

  if (!isLoading && !isAuthenticated && !children.props.isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <div className='admin'>{children}</div>;
};


export default ProtectedRoute;
