import React from "react";
import Slider from "react-slick";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import slider1 from './textfoto.jpg';
import bilsky1 from './bilsky1.jpg';
import bilsky2 from './bilsky2.jpg';
import bilsky3 from './bilsky3.jpg';
import { useNavigate } from "react-router-dom";




function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",right:30,zIndex:9999 }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",left:30,zIndex:9999  }}
        onClick={onClick}
      />
    );
  }


export default function Slider2() {
  const navigate = useNavigate();

  var settings = {
    // autoplay: true,
    // autoplaySpeed: 2000,
    // dots: true,
    infinite: true,
    // speed: 1000,
    // fade: true,
    // waitForAnimate: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  //https://navzdy.online/profiles/43ed4f19755deb9a60a583f4e0eff591
  return (
    <div style={{marginTop:'10%',marginBottom:'5%'}}>
    <Slider {...settings}>
    {/* <Grid container md={8}> */}
    <div >
  <img src={bilsky1} width={'90%'} style={{margin:'auto',padding:"5%",cursor:'pointer'}} onClick={() => navigate('profiles/c3c08b4674a07889fd4645c4bad96033')}></img> 
  </div>
  <div >
  <img src={bilsky2}  width={'90%'} style={{margin:'auto',padding:"5%",cursor:'pointer'}} onClick={() => navigate('profiles/2fd86a4cd559328923e2727decb3dee2')} ></img> 
  </div>
  <div >
  <img src={bilsky3}  width={'90%'} style={{margin:'auto',padding:"5%",cursor:'pointer'}}  onClick={() => navigate('profiles/43ed4f19755deb9a60a583f4e0eff591')} ></img> 
  </div>
  
      
    
      {/* <Grid className="masthead slider1" height="100vh" > */}
        
      {/* <Box  sx={{ top:'10%',position:'relative'}}>

        <h1 style={{margin:'40px 0 0 0'}}>Navždy.online</h1>
        <h3 style={{margin:'0px 0px 40px 0px'}}>Pomáháme uchovať spomienky na vás i vašich najbližších</h3>
        <Button className="buttonSlider" variant="outlined" size="large" sx={{color:'white',border: '1px solid white'}}>Info</Button>
        </Box> */}
        {/* </Grid > */}
        

        {/* <Grid className="masthead slider2" height="100vh"> */}
      {/* <Box  sx={{ top:'10%',position:'relative'}}>

        <h1 style={{margin:'40px 0 0 0'}}>Navždy.online</h1>
        <h3 style={{margin:'0px 0px 40px 0px'}}>Pomáháme uchovať spomienky na vás i vašich najbližších</h3>
        <Button className="buttonSlider" variant="outlined" size="large" sx={{color:'white',border: '1px solid white'}}>Info</Button>
        </Box> */}
        {/* </Grid > */}

        {/* <Grid className="masthead slider3" height="100vh" > */}
      {/* <Box  sx={{ top:'10%',position:'relative'}}>

        <h1 style={{margin:'40px 0 0 0'}}>Navždy.online</h1>
        <h3 style={{margin:'0px 0px 40px 0px'}}>Pomáháme uchovať spomienky na vás i vašich najbližších</h3>
        <Button className="buttonSlider" variant="outlined" size="large" sx={{color:'white',border: '1px solid white'}}>Info</Button>
        </Box> */}
        {/* </Grid > */}
    </Slider>
    </div>
  );
}