import React from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { serverURL } from '../../helpers';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

function StepOne({ handleNext, setData, images, data }) {
  const { t } = useTranslation();

  // Validation schema defined previously
  const validationSchema = Yup.object({
    selectedImage: Yup.string()
      .required('Vybratie plakety je povinne')
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ selectedImage: data[0] ? data[0].selected : '' }} // Start with empty string to enforce selection
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (data[3]) {
          setData([{ selected: values.selectedImage }, data[1], data[2], data[3]]);
        } else if (data[2]) {
          setData([{ selected: values.selectedImage }, data[1], data[2]]);
        } else if (data[1]) {
          setData([{ selected: values.selectedImage }, data[1]]);
        } else if (data[0]) {
          setData([{ selected: values.selectedImage }]);
        } else {
          setData([{ selected: values.selectedImage }]);
        }
        handleNext(); // Proceed to the next step
      }}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{t('stepper1')}</FormLabel>
            <RadioGroup 
              row 
              name="selectedImage" 
              value={values.selectedImage} 
              onChange={(e) => setFieldValue('selectedImage', e.target.value)} 
              sx={{ justifyContent: 'center', marginTop: 2 }}
            >
              <Grid container spacing={2}>
                {images.map((image, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card sx={{ maxWidth: 800, m: 1 }}>
                      <CardMedia
                        component="img"
                        height="350"
                        image={`${serverURL}/uploaded-files/${image?.src?.src}`}
                        alt={image.title}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div" sx={{ textTransform: 'uppercase', fontWeight: 'bold', textDecoration: 'underline' }}>
                          {image.title}
                        </Typography>
                        <FormControlLabel
                          value={image._id}
                          control={<Field as={Radio} />}
                          label=""
                          labelPlacement="bottom"
                          sx={{ marginTop: -1 }}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
            {errors.selectedImage && touched.selectedImage ? (
              <Typography color="error" variant="body2">{errors.selectedImage}</Typography>
            ) : null}
            <Button variant="outlined" type="submit" sx={{ margin: 'auto', marginTop: 1 }}>Pokračovať</Button>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
}

export default StepOne;
