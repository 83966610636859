import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl } from '@mui/material';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language);

  const handleChange = (event) => {
    const lang = event.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <FormControl variant="outlined" size="small">
      <Select
        value={language}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
            border: 'none',
            boxShadow: 'none',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            minWidth: 80,      // Nastavenie minimálnej šírky
            height: 30,        // Nastavenie výšky pre menší vzhľad
            padding: '0 2px',  // Menší padding
            fontSize: '0.5rem' // Menšia veľkosť textu
          }}
      >
        <MenuItem value="sk">
          <img src={'https://flagcdn.com/w40/sk.png'} alt="Slovakia"  />
        </MenuItem>
        <MenuItem value="cs">
          <img src={'https://flagcdn.com/w40/cz.png'} alt="Czech"  />
        </MenuItem>
      </Select>
    </FormControl>
  );
}
