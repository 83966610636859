import React, { useContext } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField, Button, DialogActions } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

// Validation schema using Yup
const validationSchema = Yup.object({
  // email: Yup.string()
  //   .email('Zadajte platný e-mail')
  //   .required('Vyžaduje sa e-mail'),
  password: Yup.string()
    .required('Vyžaduje sa heslo')
    .min(8, 'Heslo by malo mať minimálne 8 znakov'),
});

function PasswordModal({ open, handleClose }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const { changePassword } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    const status = await changePassword({...values,token});
    if(status){
      navigate('/profile')
    }
  }
  return (
    <Dialog open={open} onClose={handleClose} >
      <DialogTitle style={{background:'#fef7f0'}}>Potvrdenie hesla pre registraciu</DialogTitle>
      <Formik
        initialValues={{ password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          console.log(values);
          actions.setSubmitting(false);
          handleClose(); // Close modal on successful login
          handleLogin(values)
        }}
      >
        {({ errors, touched }) => (
          <Form style={{background:'#fef7f0'}}>
            <DialogContent>
              <Field
                as={TextField}
                type="password"
                name="password"
                label="Heslo"
                fullWidth
                margin="normal"
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                className="passwordLogin"
              />
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose} color="primary">
                Zrušiť
              </Button> */}
              <Button type="submit" variant="outlined" color="primary">
                Zadať heslo 
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default PasswordModal;
