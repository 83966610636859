import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useTranslation } from 'react-i18next';

export default function Info2() {
  const { t } = useTranslation();

  return (
    <Box sx={{ flexGrow: 1, p: { xs: 2, md: 4 } }}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
      

        <Grid container item xs={12} md={4} spacing={2} direction="column">
          <Grid item>
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <PermContactCalendarIcon sx={{ fontSize: 80, mb: 2 }} />
              <Typography variant="h5" component="h3">{t('info13')}</Typography>
              <Typography>{t('info6')}</Typography>
              <DownloadForOfflineIcon sx={{ fontSize: 80, my: 2 }} />
              <Typography variant="h5" component="h3">{t('info7')}</Typography>
              <Typography>{t('info8')}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container item xs={12} md={4} spacing={2} direction="column">
          <Grid item>
            <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
              <FolderOpenIcon sx={{ fontSize: 80, mb: 2 }} />
              <Typography variant="h5" component="h3">{t('info9')}</Typography>
              <Typography>{t('info10')}</Typography>
              <FavoriteBorderIcon sx={{ fontSize: 80, my: 2 }} />
              <Typography variant="h5" component="h3">{t('info11')}</Typography>
              <Typography>{t('info12')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
