// import React from 'react';
// import { Formik, Form, Field } from 'formik';
// import { TextField, Button } from '@mui/material';
// import { Typography } from '@mui/material';

// function StepTwo({ handleNext, handleBack , setData, data}) {
//   return (
//     <Formik
//       initialValues={data[1] ? data[1] : { name: '', biography: '', birthDate: '', deathDate: '' }}
//       onSubmit={(values) => {
//         setData(data[1] ? [data[0],values,data[2]] : [data[0],values])
//         handleNext();
//       }}
//     >
//       {({ values, handleChange }) => (
//         <Form>
//           <Typography variant="h6">Informacie o zosnulom</Typography>
//           <Field as={TextField} name="name" label="Meno a priezvisko zosnulého" onChange={handleChange} value={values.name} fullWidth margin="normal" />
//           <Field as={TextField} name="biography" label="Biografia" multiline rows={4} onChange={handleChange} value={values.biography} fullWidth margin="normal" />
//           <Field as={TextField} name="birthDate" label="Dátum narodenia" type="date" InputLabelProps={{ shrink: true }} onChange={handleChange} value={values.birthDate} fullWidth margin="normal" />
//           <Field as={TextField} name="deathDate" label="Dátum úmrtia" type="date" InputLabelProps={{ shrink: true }} onChange={handleChange} value={values.deathDate} fullWidth margin="normal" />
//           <Button onClick={handleBack}>Späť</Button>
//           <Button type="submit">Pokračovať</Button>
//         </Form>
//       )}
//     </Formik>
//   );
// }

// export default StepTwo;




import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, useColorScheme, Box } from '@mui/material';
import { Typography } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

// Define the validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string()
    .required('Vyžaduje sa meno a priezvisko zosnulého')
    .min(2, 'Meno a priezvisko by mali mať aspoň 2 znaky'),
  biography: Yup.string()
    .required('Vyžaduje sa biografia')
    .min(10, 'Biografia by mala mať aspoň 10 znakov'),
  birthDate: Yup.date()
    .required('Vyžaduje sa dátum narodenia')
    .max(new Date(), 'Dátum narodenia nemôže byť v budúcnosti'),
  deathDate: Yup.date()
    .required('Vyžaduje sa dátum úmrtia')
    .min(
      Yup.ref('birthDate'),
      'Dátum úmrtia nemôže byť skôr ako dátum narodenia'
    )
    .max(new Date(), 'Dátum úmrtia nemôže byť v budúcnosti'),
});

function StepTwo({ handleNext, handleBack, setData, data }) {
  const { t } = useTranslation();

  return (
    <span>
    <Formik
      initialValues={data[1] ? data[1] : { name: '', biography: '', birthDate: '', deathDate: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setData(data[1] ? [data[0], values, data[2]] : [data[0], values]);
        handleNext();
      }}
    >
      {({ values, handleChange, errors, touched }) => (
        <Form>
          <Typography variant="h6">{t('stepper2')}</Typography>
          <Field as={TextField} name="name" label="Meno a priezvisko zosnulého" onChange={handleChange} value={values.name} error={touched.name && !!errors.name} helperText={touched.name && errors.name} fullWidth margin="normal" />
          <Field as={TextField} name="biography" label="Biografia" multiline rows={8} onChange={handleChange} value={values.biography} error={touched.biography && !!errors.biography} helperText={touched.biography && errors.biography} fullWidth margin="normal" />
          <Field as={TextField} sx={{m:2}} style={{colorScheme:'dark'}} name="birthDate" label="Dátum narodenia" type="date" InputLabelProps={{ shrink: true }} onChange={handleChange} value={values.birthDate} error={touched.birthDate && !!errors.birthDate} helperText={touched.birthDate && errors.birthDate}  margin="normal" />
          <Field as={TextField} sx={{m:2}} style={{colorScheme:'dark'}} name="deathDate" label="Dátum úmrtia" type="date" InputLabelProps={{ shrink: true }} onChange={handleChange} value={values.deathDate} error={touched.deathDate && !!errors.deathDate} helperText={touched.deathDate && errors.deathDate}  margin="normal" />
          <div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>

          <Button variant="outlined"  onClick={handleBack} color='secondary'>{t('back')}</Button>
          <Button variant="outlined"  type="submit" >{t('continue')}</Button>
          </Box>
          </div>
        </Form>
      )}
    </Formik>
    
    </span>
  );
}

export default StepTwo;




// import React from 'react';
// import { Formik, Form, Field, useField } from 'formik';
// import { TextField, Button, Typography } from '@mui/material';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import * as Yup from 'yup';

// // Custom date picker input component for Formik
// const FormikDatePicker = ({ ...props }) => {
//   const [field, meta, helpers] = useField(props);
//   return (
//     <DatePicker
//       {...field}
//       {...props}
//       selected={(field.value && new Date(field.value)) || null}
//       onChange={(value) => helpers.setValue(value)}
//       customInput={<TextField fullWidth />}
//       dateFormat="yyyy-MM-dd"
//     />
//   );
// };

// // Validation schema
// const validationSchema = Yup.object({
//   name: Yup.string()
//     .required('Vyžaduje sa meno a priezvisko zosnulého')
//     .min(2, 'Meno a priezvisko by mali mať aspoň 2 znaky'),
//   biography: Yup.string()
//     .required('Vyžaduje sa biografia')
//     .min(10, 'Biografia by mala mať aspoň 10 znakov'),
//   birthDate: Yup.date()
//     .required('Vyžaduje sa dátum narodenia')
//     .max(new Date(), 'Dátum narodenia nemôže byť v budúcnosti'),
//   deathDate: Yup.date()
//     .required('Vyžaduje sa dátum úmrtia')
//     .min(
//       Yup.ref('birthDate'),
//       'Dátum úmrtia nemôže byť skôr ako dátum narodenia'
//     )
//     .max(new Date(), 'Dátum úmrtia nemôže byť v budúcnosti'),
// });

// function StepTwo({ handleNext, handleBack, setData, data }) {
//   return (
//     <Formik
//       initialValues={data[1] ? data[1] : { name: '', biography: '', birthDate: '', deathDate: '' }}
//       validationSchema={validationSchema}
//       onSubmit={(values) => {
//         setData(data[1] ? [data[0], values, data[2]] : [data[0], values]);
//         handleNext();
//       }}
//     >
//       {({ errors, touched }) => (
//         <Form>
//           <Typography variant="h6">Informacie o zosnulom</Typography>
//           <Field as={TextField} name="name" label="Meno a priezvisko zosnulého" error={touched.name && !!errors.name} helperText={touched.name && errors.name} fullWidth margin="normal" />
//           <Field as={TextField} name="biography" label="Biografia" multiline rows={4} error={touched.biography && !!errors.biography} helperText={touched.biography && errors.biography} fullWidth margin="normal" />
//           <FormikDatePicker name="birthDate" label="Dátum narodenia" error={touched.birthDate && !!errors.birthDate} helperText={touched.birthDate && errors.birthDate} />
//           <FormikDatePicker name="deathDate" label="Dátum úmrtia" error={touched.deathDate && !!errors.deathDate} helperText={touched.deathDate && errors.deathDate} />
//           <Button onClick={handleBack}>Späť</Button>
//           <Button type="submit">Pokračovať</Button>
//         </Form>
//       )}
//     </Formik>
//   );
// }

// export default StepTwo;
