import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function About() {
  return (
    <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1">
          Welcome to our website. Here you can find information about our mission, team, and more. Our goal is to provide an excellent service to all our visitors and ensure a memorable experience.
        </Typography>
      </Box>
    </Container>
  );
}

export default About;
