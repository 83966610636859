
import React, { useState,useEffect } from 'react';

import Gallery from './Gallery';
import ModalGallery from './ModalGallery';
import { useQuery } from 'react-query';

import { useSnackbar } from 'notistack';
import LoadingIndicator from '../../components/LoadingIndicator';
import instance from '../../helpers';


// const fetch = async (limit,page)=>{
//     const response = await instance().get(`/gallery?page=${page}&limit=${limit}&sortBy=createdAt,desc`);
//     return response.data;
// }

const GalleryContainer = ({field,initialValues,path}) => {
   
    // const { enqueueSnackbar } = useSnackbar();
    const [galleryInitialValues, setgalleryInitialValues] = useState(initialValues);
  
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);

   

    // const { data, status } = useQuery([pageIndex,pageSize,open], () => fetch(pageSize,pageIndex), { enabled: open,cacheTime:0 });
    
    // // if (status === 'loading') return LoadingIndicator;
    // if (status === 'error'){
    //     enqueueSnackbar(`Error fetching data gallery`, { variant: 'error' , autoHideDuration: 1000});
    // }
    
    return (
        <span style={{padding:'20px',margin:20 ,width:"50%"}}>
            <h2>Gallery</h2>
            <div style={{padding:'32px',marginBottom:'32px'}}>
                <Gallery field={field} initialValues={initialValues} handleOpen={handleOpen} gallery={galleryInitialValues} setGallery={setgalleryInitialValues} >
                <ModalGallery pageSize={pageSize} path={path} open={open} setPageSize={setPageSize}  galleryProduct={galleryInitialValues} setGalleryProduct={setgalleryInitialValues} pageIndex={pageIndex} setPageIndex={setPageIndex} handleClose={handleClose}  field={field} />
                </Gallery>
                
            </div>
               
        </span>
    );
}

export default GalleryContainer;