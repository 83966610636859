import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import instance, { serverURL } from '../helpers';
import { useLocation, useParams } from 'react-router-dom';
// import Layout from './Layout';

const fetchData = async (sessionId)=>{
    const response = await instance().get(`/cancel-payment?session_id=${sessionId}`);
    return response.data;
  }
function Cancel() {
    const location = useLocation();

    const getSessionId = () => {
        const params = new URLSearchParams(location.search);
        return params.get('session_id');
      };
    
    const sessionId = getSessionId();
    const { enqueueSnackbar } = useSnackbar();
    const { data, status } = useQuery([], () => fetchData(sessionId),{cacheTime:0});
    // if (status === 'loading') return LoadingIndicator;
    if (status === 'error') {
        enqueueSnackbar(`Error fetching data /model-lines`, { variant: 'error', autoHideDuration: 1000 });
    };

    // console.log('datatat',data?.data);
    // const carSeries = data && data.data || []

  return (
    // <Layout>

    <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
        Vaša objednávka bola zrušená
        </Typography>
        <Typography variant="body1">
        Ak máte nejaké otázky, prosím kontaktujte nás.
        </Typography>
   
      </Box>
    </Container>
    // </Layout>
  );
}

export default Cancel;
