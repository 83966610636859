import React, { createContext, useState, useEffect } from 'react';
import axios, { reInstance } from './helpers';
import { useSnackbar } from 'notistack';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [role, setRole] = useState('');


  const logout = async (values) => {
      localStorage.removeItem('token');
      setIsAuthenticated(false);
  };

    
  const changePassword = async (values) => {
    try {
      const response = await axios().post('/set-password', values);
      localStorage.setItem('token', values.token);
      setIsAuthenticated(true);
      await reInstance(values.token);
      return true;
    }catch (error) {
      const errorMessage = error.response?.data.msg || 'An error occurred';
      enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 1000  });
      return false;
    }
  };
  
  const login = async (values) => {
    try {
      const response = await axios().post('/login', values);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
      setRole(response.data.role);

      await reInstance(response.data.token);
      return {status: true,role: response.data.role};
    }catch (error) {
      const errorMessage = error.response?.data.msg || 'An error occurred';
      enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 1000  });
      return {status: false};
    }
  };

  const register = async (values) => {
    try {
      const response = await axios().post('/register', values);
      localStorage.setItem('token', response.data.token);
      setIsAuthenticated(true);
      await reInstance(response.data.token);
      return true
    }catch (error) {
      const errorMessage = error.response?.data.msg || 'An error occurred';
      enqueueSnackbar(errorMessage, { variant: 'error', autoHideDuration: 1000  });
      return false;
    }
  };

  const verifyToken = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await axios().get('/check-tokken', {
          headers: { Authorization: `Bearer ${token}` }
        });

        setIsAuthenticated(Boolean(response.data.status));
        setRole(response.data.role);

      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
      // enqueueSnackbar(error, { variant: 'error', autoHideDuration: 1000  });
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, setIsAuthenticated, login, register, verifyToken,logout,changePassword,role }}>
      {children}
    </AuthContext.Provider>
  );
};
