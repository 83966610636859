import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ThankYou() {
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom>
        {t('sucess1')}      
        </Typography>
        <Typography variant="body1">
        {t('sucess3')}      
        </Typography>
   
      </Box>
    </Container>
  );
}

export default ThankYou;
