import React from 'react';
import GenericTable from '../components/GenericTable';
import AdminLayout from '../layouts/AdminLayout';

const OrderPage = ({name,options}) => {
  return (
    <AdminLayout>
    <div style={{ margin: '20px' }}>
    <GenericTable 
        name={name}
        columns={options(true)}
    />
    </div>
    </AdminLayout>
  );
};

export default OrderPage;
