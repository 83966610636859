// const options = (isTable = false) =>{
//     const data = 
//     [
//       { name: 'name', label: 'Name', type: 'text' },
//       { name: 'companyName', label: 'Company Name', type: 'text' },
//       { name: 'address', label: 'Address', type: 'text' },
//       { name: 'taxId', label: 'Tax ID', type: 'text' },
//       { name: 'vatId', label: 'VAT ID', type: 'text' },
//       { name: 'license', label: 'License', type: 'text' },
//       { name: 'email', label: 'Email', type: 'email' },
//       { name: 'vatNumber', label: 'VAT Number', type: 'text' },
//       { name: 'password', label: 'Password', type: 'password' },
//       { name: 'authToken', label: 'Auth Token', type: 'text' },
//       { name: 'mobile', label: 'Mobile', type: 'tel' },
//       { name: 'active', label: 'Active', type: 'checkbox' },
//       { name: 'createdAt', labelname: 'Created At', type: 'date' },
//       { name: 'deletedAt', labelname: 'Deleted At', type: 'date' },
//     ]
//     return !isTable ? data.filter(e => e.label) : data.map(e => ({Header: e.label ? e.label : e.labelname ,accessor: e.name}));
// }
// export default options;


const options = (isTable = false) => {
  const data = [

    // { name: 'category', label: 'Category', type: 'autocomplete',populate:'title',fetch:'categories',multiple:false },
    { name: 'title', label: 'Titulok', type: 'text' },
    { name: 'src', label: 'IMG', type: 'image',populate: 'src' },


    // { name: 'specification', label: 'Specification', type: 'text', multiline: true},

    // { name: 'models', label: 'Models', type: 'autocomplete',populate:'title',fetch:'models',multiple:true },

    // { name: 'ean', label: 'EAN', type: 'text' },
    // { name: 'compatibility', label: 'Compatibility', type: 'text',multiline: true},
    // { name: 'quantity', label: 'Quantity', type: 'number' },
    // { name: 'age', label: 'Age', type: 'number' },

    // { name: 'price', label: 'Price', type: 'number' },


      // { name: 'shipments', label: 'Shipments', type: 'autocomplete',populate:'title',fetch:'shipments',multiple:true },

      // { name: 'createdAt', hidden: true, label: 'Created At', type: 'date' ,default: new Date() },
      // { name: 'updatedAt', hidden: true, label: 'Updated At', type: 'date', default: new Date() },
      // { name: 'delete', hidden: true, label: 'Delete', type: 'checkbox' }
  ];

  // TODO a aj vsetky ostatne files,nech sa to robi priamo v table a generic form
  return !isTable ? data.filter(e => e.label) : data.filter(e => !e.hidden).map(e => ({ Header: e.label, accessor: e.name, type: e.type, populate: e.populate,multiple:e.multiple,multiline:e.multiline }));
}
export default options;