const options = (isTable = false) =>{
    const data = 
    [
      { name: 'fullName', label: 'Celé meno', type: 'text' },
      { name: 'postalCode', label: 'PSČ', type: 'text' },
      { name: 'streetAddress', label: 'Adresa', type: 'text' },
      { name: 'city', label: 'Mesto', type: 'text' },
      { name: 'country', label: 'Krajina', type: 'text' },
    
      { name: 'email', label: 'Email', type: 'email' },
      { name: 'phone', label: 'Mobil', type: 'tel' },
      { name: 'createdAt', label: 'Vytvorené',  type: 'date', default: new Date() },
    
      // { name: 'createdAt', label: 'Vytvorené', hidden: true, type: 'date', default: new Date() },
    ];
    
      return !isTable ? data.filter(e => e.label) : data.filter(e => !e.hidden).map(e => ({ Header: e.label, accessor: e.name,type: e.type, populate: e.populate,multiple:e.multiple ,form:e.form}));
    }
export default options;
